<template>
    <div class="data-table">
        <b-row v-if="!simple" class="action-bar">
            <b-col class="left" cols="auto">
                <slot name="action-bar-left"></slot>
            </b-col>
            <b-col class="right text-right">
                <slot name="action-bar-right"></slot>
            </b-col>
        </b-row>

        <loader v-if="isBusy" />
        <template v-else-if="data && data.length">
            <b-row
                v-for="(n, i) in data"
                :key="`row-${i}`"
                class="table-row"
                :class="{ hoverable: hoverable }"
                @click="$emit('row-clicked', n)"
            >
                <template v-for="k in keys">
                    <slot :name="`cell(${k.id})`" :item="n[k.id]" :data="n"></slot>
                </template>
            </b-row>
        </template>
        <template v-else>
            <slot name="no-data">
                <ContentBubble class="nodata">
                    <div class="wrapper">
                        <icon icon="empty" />
                        <span class="text" v-html="$t('html.empty')"></span>
                        <b-button @click="$emit('reset-filter')" size="sm" variant="dark">{{ $t('resetFilter') }}</b-button>
                    </div>
                </ContentBubble>
            </slot>
        </template>

        <b-row v-if="!simple && data && data.length" class="pagination-bar">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="left"
                    first-number
                    last-number
                    @change="(page) => $emit('page-change', page)"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ContentBubble from '@/components/ContentBubble.vue'

export default {
    name: 'data-table',
    props: {
        data: { type: Array, default: () => [] },
        totalRows: { type: Number, default: 0 },
        perPage: { type: Number, default: 0 },
        page: { type: Number, default: 1 },
        keys: { type: Array, default: () => [] },
        isBusy: { type: Boolean, default: false },
        simple: { type: Boolean, default: false },
        hoverable: { type: Boolean, default: true }
    },
    data: function () {
        return {
            currentPage: this.page
        }
    },
    components: {
        ContentBubble
    },
    watch: {
        page: function () {
            this.currentPage = this.page
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.data-table .nodata {
    .wrapper {
        display: flex;
        align-items: center;
    }

    img {
        margin-right: 15px;
    }

    .text {
        color: $light;
        font-size: 16px;
    }

    button {
        margin-left: auto;
    }

    .total {
        text-align: right;
    }
}
</style>
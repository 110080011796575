import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/parties',
        name: 'parties',
        component: () => import(/* webpackChunkName: "parties" */ '../views/Parties.vue')
    },
    {
        path: '/donors',
        name: 'donors',
        component: () => import(/* webpackChunkName: "donors" */ '../views/Donors.vue')
    },
    {
        path: '/party/:slug',
        name: 'party-detail',
        props: true,
        component: () => import(/* webpackChunkName: "party-detail" */ '../views/PartyDetail.vue')
    },
    {
        path: '/donor/:slug',
        name: 'donor-detail',
        props: true,
        component: () => import(/* webpackChunkName: "donor-detail" */ '../views/DonorDetail.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router

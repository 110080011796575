<template>
    <layout-detail class="party-detail"
        :tabs="tabs"
        :busy="isBusy"
    >
        <template #title>
            <icon class="party-logo" icon="party" /> <h1>{{ data.name }}</h1>
        </template>
        <template #subtitle>
            {{ $t("party.subtitle") }}
        </template>

        <template #tab(0)>
            <TabOverview :data="data" />
        </template>

        <template #tab(1)>
            <TabDonations :data="data" />
        </template>

        <template #tab(2)>
            <TabFHealth :data="data" />
        </template>

        <template #tab(3)>
            <TabBudget :data="data" />
        </template>
    </layout-detail>
</template>

<script>
import TabOverview from '@/components/detail/party/TabOverview.vue'
import TabDonations from '@/components/detail/party/TabDonations.vue'
import TabBudget from '@/components/detail/party/TabBudget.vue'
import TabFHealth from '@/components/detail/party/TabFHealth.vue'

import {CONFIG} from '@/config.js'

export default {
    name: "PartyDetail",
    props: {
        slug: {type: [String, Number], required: true}
    },
    components: {
        TabOverview,
        TabDonations,
        TabBudget,
        TabFHealth
    },
    data: function() {
        return {
            isBusy: false,
            data: null,
            enabledTabs: {}
        }
    },
    computed: {
        tabs: function() {
            return [
                {label: this.$t("party.tabs.overview")},
                {label: this.$t("party.tabs.donations"), disabled: !this.enabledTabs.donos},
                {label: this.$t("party.tabs.health")},
                {label: this.$t("party.tabs.budget"), disabled: !this.enabledTabs.budgets}
            ]
        }
    },
    created: function() {
        this.isBusy = true

        this.loadDetail()
            .then(() => {
                this.$root.$emit("set-page-title", this.$t("party.seo.title", {party: this.data.name}))
                this.$root.$emit("set-page-description", this.$t("party.seo.description"))

                return this.loadTabs()
            })
            .finally(() => this.isBusy = false)
    },
    methods: {
        loadDetail: function() {
            return this.$store.getters.api
                .get(`${CONFIG.api.endpoints.party}${this.slug}/`, {params: {advanced: true}})
                .then(response => {
                    this.data = response.data
                })
                .catch(e => {
                    this.data = null
                    console.log(e)
                })
        },
        loadTabs: function() {
            return this.$store.getters.api
                .get("party_tabs", {params: {party: this.data.id}})
                .then(response => {
                    this.enabledTabs = response.data
                })
                .catch(e => {
                    this.enabledTabs = {}
                    console.log(e)
                })
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/utils";

.party-detail {
    .title {
        .party-logo {
            @include make-logo();
            margin-right: 20px;
        }
    }
}
</style>
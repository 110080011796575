var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tab"},[_c('div',{staticClass:"board"},[_c('b-row',[_c('b-col',{staticClass:"mb-3 col col-12 col-sm-6 col-lg-3 order-1"},[_c('ValueBubble',{attrs:{"icon":"count","title":_vm.$t('party.donos.count')}},[_vm._v(" #"+_vm._s(_vm.data.donations_count_total)+" ")])],1),_c('b-col',{staticClass:"mb-3 col col-12 col-sm-12 col-lg-6 order-3 order-lg-2"},[_c('ValueBubble',{attrs:{"icon":"sum","title":_vm.$t('party.donos.sum')}},[_c('icon',{staticClass:"align-top",attrs:{"icon":"gift_in","width":"16"}}),_vm._v(_vm._s(_vm._f("price")(_vm.data.donations_amount_total,0))+" ")],1)],1),_c('b-col',{staticClass:"mb-3 col col-12 col-sm-6 col-lg-3 order-2 order-lg-3"},[_c('ValueBubble',{attrs:{"icon":"calendar","title":_vm.$t('party.donos.period')}},[_vm._v(" "+_vm._s(_vm.data.donations_min_year)+"-"+_vm._s(_vm.data.donations_max_year)+" ")])],1)],1)],1),_c('h2',[_vm._v(_vm._s(_vm.$t('party.donos.byYearAndDonor')))]),_c('DataTable',{staticClass:"donos-by-year-and-donor",attrs:{"data":_vm.donos,"keys":_vm.donosTableKeys,"is-busy":_vm.donosBusy,"per-page":_vm.selectedPageSize,"total-rows":_vm.totalRows,"page":_vm.currentPage},on:{"page-change":function (page) { return (_vm.currentPage = page); },"row-clicked":function (n) { return _vm.$router.push({ name: 'donor-detail', params: { slug: n.slug } }); },"reset-filter":_vm.resetFilter},scopedSlots:_vm._u([{key:"action-bar-left",fn:function(){return [_c('FilterMultiselect',{staticClass:"donor",attrs:{"loader":_vm.getDonorOptions,"label":_vm.$t('donor.label'),"placeholder":_vm.$t('filter.allAlt2')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('DonorName',{staticClass:"text-truncate",attrs:{"data":option,"simple":""}})]}},{key:"tag",fn:function(ref){
var option = ref.option;
return [_c('DonorName',{attrs:{"data":option,"simple":"","element":"span"}})]}},{key:"singleValue",fn:function(ref){
var value = ref.value;
return [_c('DonorName',{staticClass:"value",attrs:{"data":value,"simple":"","element":"span"}})]}},{key:"multipleValue",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(_vm.$tc('donor.pagination.selected', values.length))+" ")]}}]),model:{value:(_vm.selectedDonors),callback:function ($$v) {_vm.selectedDonors=$$v},expression:"selectedDonors"}}),_c('FilterDropdown',{staticClass:"year",attrs:{"bordered":true,"label":_vm.$t('donor.year'),"options":_vm.yearOptions},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('FilterDropdown',{staticClass:"donation-type",attrs:{"bordered":true,"label":_vm.$t('donation.type.label'),"options":_vm.donationTypeOptions},model:{value:(_vm.selectedDonationType),callback:function ($$v) {_vm.selectedDonationType=$$v},expression:"selectedDonationType"}}),_c('FilterDropdown',{staticClass:"type",attrs:{"bordered":true,"label":_vm.$t('donor.risks.filterLabel'),"options":_vm.riskTypeOptions},model:{value:(_vm.selectedRiskType),callback:function ($$v) {_vm.selectedRiskType=$$v},expression:"selectedRiskType"}})]},proxy:true},{key:"action-bar-right",fn:function(){return [_c('FilterDropdown',{staticClass:"sorting",attrs:{"label":_vm.$t('sorting.label'),"options":_vm.sortingOptions,"right":""},scopedSlots:_vm._u([{key:"selected",fn:function(data){return [_vm._v(" "+_vm._s(data.label)+" "),_c('icon',{attrs:{"icon":"arrow_down"}})]}}]),model:{value:(_vm.selectedSortOption),callback:function ($$v) {_vm.selectedSortOption=$$v},expression:"selectedSortOption"}})]},proxy:true},{key:"cell(logo)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"logo"},[_c('icon',{attrs:{"icon":n.person == 'PHYSICAL' ? 'person' : 'company'}})],1)]}},{key:"cell(name)",fn:function(ref){
var n = ref.data;
var d = ref.data;
return [_c('div',{staticClass:"col col-5 col-sm-6 col-md-4 header_row"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col col-icon d-none d-sm-block"},[_c('div',{staticClass:"logo"},[_c('icon',{attrs:{"icon":n.person == 'PHYSICAL' ? 'person' : 'company'}})],1)]),_c('div',{staticClass:"col"},[_c('DonorName',{attrs:{"data":n}}),(_vm.isDonorRisky(d))?[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.risks.label'))+":")]),_c('span',{staticClass:"value risks"},[_vm._v(" "+_vm._s(_vm._f("list")(_vm.getDonorRisks(d))))])]:_vm._e()],2)])])]}},{key:"cell(donation_types)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donation.type.label'))+":")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("list")(_vm.getDonationTypes(n))))])])]}},{key:"cell(year)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.year'))+":")]),(_vm.selectedYear.value === null)?_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("range")(n)))]):_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$getSelectedOptionValue(_vm.selectedYear)))])])]}},{key:"cell(donations)",fn:function(ref){
var n = ref.item;
var d = ref.data;
return [_c('div',{staticClass:"col col-7 col-sm-6 col-md-4 total"},[_c('icon',{attrs:{"icon":"gift_out"}}),_vm._v(_vm._s(_vm._f("price")(n,0))+" "),_c('div',{staticClass:"d-block d-sm-none"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("list")(_vm.getDonationTypes(d.donation_types))))]),(_vm.selectedYear.value === null)?_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("range")(d.year)))]):_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$getSelectedOptionValue(_vm.selectedYear)))])])],1)]}}])}),_c('h2',[_vm._v(_vm._s(_vm.$t('party.donos.byYear.label')))]),_c('DataTable',{staticClass:"donos-by-year",attrs:{"data":_vm.data.donations_by_year,"keys":_vm.donosByYearTableKeys,"hoverable":false,"simple":""},scopedSlots:_vm._u([{key:"cell(year)",fn:function(ref){
var n = ref.item;
var d = ref.data;
return [_c('div',{staticClass:"col col-2 col-sm-1 text-center"},[_vm._v(" "+_vm._s(n)+" "),_c('div',{staticClass:"d-block d-sm-none"},[(_vm.verifyDonations(d.donations, d.checksum))?_c('icon',{attrs:{"icon":"shield"}}):_c('icon',{attrs:{"icon":"warn_triangle"}})],1)])]}},{key:"cell(donations)",fn:function(ref){
var d = ref.data;
return [_c('div',{staticClass:"col col-9 col-sm-5 check"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.donos.byYear.amount'))+": ")]),_vm._v(_vm._s(_vm._f("price")(d.donations,0))+" "),_c('span',{staticClass:"d-block d-sm-none"},[_c('br'),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.donos.byYear.check'))+": ")]),_vm._v(_vm._s(_vm._f("price")(d.checksum,0))+" ")])])]}},{key:"cell(checksum)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col col-9 col-sm-5 d-none d-sm-block check"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.donos.byYear.check'))+": ")]),_vm._v(_vm._s(_vm._f("price")(n,0))+" ")])]}},{key:"cell(icon)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col col-1 d-none d-sm-block"},[(_vm.verifyDonations(n.donations, n.checksum))?_c('icon',{attrs:{"icon":"shield"}}):_c('icon',{attrs:{"icon":"warn_triangle"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
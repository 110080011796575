export default {
    computed: {
        riskTypeOptions: function() {
            return [
                {label: this.$t("filter.all"), value: null},
                {label: this.$t("donor.risks.noRisk"), value: "none"},
                {label: this.$t("donor.risks.tenders"), value: "tenders"},
                {label: this.$t("donor.risks.eusubs"), value: "subs"}
            ]
        }
    },
    methods: {
        getDonorRisks: function(data) {
            var risks = []

            if (data.risk_eusubs) {
                risks.push(this.$t("donor.risks.eusubs"))
            }

            if (data.risk_tenders) {
                risks.push(this.$t("donor.risks.tenders"))
            }

            return risks
        },
        isDonorRisky: function(data) {
            return data.risk_eusubs || data.risk_tenders
        }
    }
}
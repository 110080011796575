var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tab"},[(_vm.bubbleData)?_c('div',{staticClass:"board"},[_c('ContentBubble',{attrs:{"icon":"finance","variant":"success"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('html.eusubsBubble', {
                        company: _vm.donorName,
                        min_year: _vm.bubbleData.min_year,
                        max_year: _vm.bubbleData.max_year,
                        count: _vm.bubbleData.count,
                        amount: _vm.$options.filters.humanize_price(_vm.bubbleData.total)
                    })
                )}})])],1):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.$t('donor.eusubs.topFive')))]),_c('DataTable',{staticClass:"top-eusubs",attrs:{"data":_vm.subs,"keys":_vm.tableKeys,"hoverable":false,"is-busy":_vm.busy,"simple":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
                var n = ref.item;
                var d = ref.data;
return [_c('div',{staticClass:"col col-6 col-md-5 header_row"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(n))]),_c('span',{staticClass:"value d-block d-md-none"},[_vm._v(_vm._s(d.year))])])]}},{key:"cell(year)",fn:function(ref){
                var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2"},[(n)?[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.eusubs.year'))+": ")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(n))])]:_vm._e()],2)]}},{key:"cell(amount)",fn:function(ref){
                var n = ref.item;
return [_c('div',{staticClass:"col col-6 col-md-5 total"},[_vm._v(" "+_vm._s(_vm._f("price")(n,0))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <layout class="parties">
        <h1>{{ $t('party.title') }}</h1>

        <div class="d-none d-sm-block">
            <ChartBubble
                v-if="chartData.length"
                class="chart-bubble"
                :title="$t('charts.partiesByYear.label')"
                :legend="[
                    { color: color.total, label: $t('charts.partiesByYear.total') },
                    { color: color.dono, label: $t('charts.partiesByYear.donos') }
                ]"
            >
                <GChart type="ColumnChart" :data="chart" :options="chartOptions" />
            </ChartBubble>
        </div>

        <DataTable
            :per-page="selectedPageSize"
            :total-rows="totalRows"
            :page="currentPage"
            :data="parties"
            :keys="partiesTableKeys"
            :is-busy="isPartiesLoading"
            @page-change="(page) => (currentPage = page)"
            @row-clicked="(n) => $router.push({ name: 'party-detail', params: { slug: n.slug } })"
            @reset-filter="resetFilter"
        >
            <template #action-bar-left>
                <FilterMultiselect
                    class="party"
                    v-model="selectedParties"
                    :loader="getPartyOptions"
                    :label="$t('party.label')"
                    :placeholder="$t('filter.allAlt')"
                >
                    <template #option="{ option }">
                        <div class="text-truncate">{{ option.name }}</div>
                    </template>

                    <template #tag="{ option }">
                        {{ option.name }}
                    </template>

                    <template #singleValue="{ value }">
                        <span class="value">{{ value.name }}</span>
                    </template>

                    <template #multipleValue="{ values }">
                        {{ $tc('party.pagination.selected', values.length) }}
                    </template>
                </FilterMultiselect>

                <FilterDropdown class="year" :bordered="true" :label="$t('party.year')" :options="yearOptions" v-model="selectedYear">
                </FilterDropdown>

                <FilterDropdown
                    class="hindex"
                    :bordered="true"
                    :label="$t('party.index')"
                    :options="hIndexOptions"
                    v-model="selectedHIndex"
                >
                </FilterDropdown>
            </template>

            <template #action-bar-right>
                <FilterDropdown class="sorting" :label="$t('sorting.label')" :options="sortingOptions" v-model="selectedSortOption" right>
                    <template #selected="data"> {{ data.label }} <icon icon="arrow_down" /> </template>
                </FilterDropdown>
            </template>

            <template #cell(name)="{ data: n }">
                <div class="col col-6 col-md-5 col-lg-4 col-xxl-3 header_row">
                    <div class="row align-items-center">
                        <div class="col col-icon d-none d-sm-block">
                            <div class="logo">
                                <icon icon="party" />
                            </div>
                        </div>
                        <div class="col">
                            <PartyName :data="n" />
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(income)="{ item: n, data: d }">
                <div class="col d-none d-xxl-block col-xxl-2 text-right text-sm-left">
                    <div class="label">{{ $t('party.income') }}:</div>
                    <div class="value income">+ {{ (n / 1000) | price(0) }}</div>
                    <div class="label">{{ $t('party.outcome') }}:</div>
                    <div class="value outcome">- {{ (d.outcome / 1000) | price(0) }}</div>
                </div>
            </template>

            <template #cell(debts)="{ item: n }">
                <div class="col d-none d-lg-block col-lg-2 col-xxl-2 text-right text-sm-left">
                    <div class="label">{{ $t('party.debts') }}:</div>
                    <div class="value debts">{{ (n / 1000) | price(0) }}</div>
                </div>
            </template>
            <template #cell(topDonor)="{ item: n }">
                <div class="col d-none d-md-block col-md-3 col-lg-3 col-xxl-2 text-right text-sm-left">
                    <template v-if="n">
                        <div class="label">{{ $t('party.topDonor') }}:</div>
                        <DonorName simple class="value top-donor" :data="n" />
                    </template>
                </div>
            </template>
            <template #cell(donations)="{ item: n, data: d }">
                <div class="col col-6 col-md-4 col-lg-3 col-xxl-3 total">
                    <icon icon="gift_in" />{{ n | price(0) }}
                    <div class="d-block d-xxl-none sub_block">
                        <div class="label">{{ $t('party.income') }}:</div>
                        <div class="value income">+ {{ (d.income / 1000) | price(0) }}</div>
                        <div class="label">{{ $t('party.outcome') }}:</div>
                        <div class="value outcome">- {{ (d.outcome / 1000) | price(0) }}</div>
                        <div class="d-lg-none label">{{ $t('party.debts') }}:</div>
                        <div class="d-lg-none value debts">{{ (d.debts / 1000) | price(0) }}</div>
                    </div>
                </div>
            </template>
        </DataTable>
    </layout>
</template>

<script>
import FilterDropdown from '@/components/FilterDropdown.vue'
import FilterMultiselect from '@/components/FilterMultiselect.vue'
import DataTable from '@/components/DataTable.vue'
import PartyName from '@/components/PartyName.vue'
import DonorName from '@/components/DonorName.vue'
import ChartBubble from '@/components/ChartBubble.vue'

import chartMixin from '@/plugins/chartMixin.js'

export default {
    name: 'Parties',
    components: {
        FilterDropdown,
        FilterMultiselect,
        DataTable,
        PartyName,
        DonorName,
        ChartBubble
    },
    mixins: [chartMixin],
    data: function () {
        return {
            isPartiesLoading: false,
            selectedSortOption: null,
            selectedPageSize: 10,
            selectedHIndex: null,
            selectedYear: null,
            currentPage: 1,
            totalRows: 0,
            chartData: [],
            selectedParties: []
        }
    },
    created: function () {
        this.loadChartData()

        this.$store.dispatch('loadPartyYears').then(() => this.resetFilter())
    },
    computed: {
        parties: function () {
            return this.$store.state.parties
        },
        sortingOptions: function () {
            return [
                { label: this.$t('party.sorting.options.dono'), value: '-stats__donations' },
                { label: this.$t('party.sorting.options.income'), value: '-stats__income' },
                { label: this.$t('party.sorting.options.outcome'), value: '-stats__outcome' },
                { label: this.$t('party.sorting.options.debt'), value: '-stats__debts' }
            ]
        },
        pageSizeOptions: function () {
            return [10, 20]
        },
        filters: function () {
            var map = {
                hindex: this.selectedHIndex,
                year: this.selectedYear,
                parties: this.selectedParties.length ? this.selectedParties.map((n) => n.id).join(',') : null
            }

            var filters = {}
            for (const f in map) {
                var val = this.$getSelectedOptionValue(map[f])
                if (val !== null) {
                    filters[f] = val
                }
            }

            return filters
        },
        params: function () {
            return {
                ...{
                    limit: this.selectedPageSize,
                    offset: (this.currentPage - 1) * this.selectedPageSize,
                    order_by: this.$getSelectedOptionValue(this.selectedSortOption)
                },
                ...this.filters
            }
        },
        hIndexOptions: function () {
            return [{ label: this.$t('filter.all'), value: null }, 'A', 'B', 'C']
        },
        yearOptions: function () {
            return this.$store.state.partyYears
        },
        partiesTableKeys: function () {
            return [{ id: 'name' }, { id: 'income' }, { id: 'debts' }, { id: 'topDonor' }, { id: 'donations' }]
        },
        color: function () {
            return {
                dono: '#f0abb9',
                total: '#e4002c'
            }
        },
        chart: function () {
            var chart = [['year', 'donos', 'total']]
            if (this.chartData.length) {
                chart = chart.concat(this.chartData.map((n) => [n.year.toString().substring(2), n.donos, n.total - n.donos]))
            }
            return chart
        },
        chartOptions: function () {
            var vAxis = { ...this.defaultChartOptions.vAxis, ...{ ticks: [0, this.maxChartValue] } }

            return {
                ...this.defaultChartOptions,
                ...{
                    isStacked: true,
                    colors: [this.color.dono, this.color.total],
                    series: {
                        0: { targetAxisIndex: 1 },
                        1: { targetAxisIndex: 1 }
                    },
                    height: 300,
                    vAxis: vAxis
                }
            }
        },
        maxChartValue: function () {
            return this.chartData.reduce((max, n) => Math.max(n.total, max), 0)
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.currentPage = 1
            }
        },
        params: {
            deep: true,
            handler: function () {
                this.loadParties(this.params)
            }
        }
    },
    methods: {
        resetFilter: function () {
            this.selectedSortOption = this.sortingOptions[0]
            this.selectedHIndex = this.hIndexOptions[0]
            this.selectedYear = this.yearOptions[0]
        },
        loadParties: function (params) {
            this.isPartiesLoading = true

            params = { ...params, ...{ no_stats: false } }

            this.$store
                .dispatch('loadParties', params)
                .then((r) => (this.totalRows = r.data.meta.total_count))
                .finally(() => (this.isPartiesLoading = false))
        },
        loadChartData: function () {
            this.$store.getters.api
                .get('chart/parties')
                .then((response) => {
                    this.chartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.chartData = null
                })
        },
        getPartyOptions: function (search) {
            return new Promise((resolve, reject) => {
                var params = {
                    q: search
                }

                this.$store.getters.api
                    .get('party_options', { params: params })
                    .then((response) => resolve(response.data))
                    .catch((e) => reject(e))
            })
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.parties {
    .table-row {
        .income,
        .outcome,
        .debts {
            font-family: $font-family-monospace;
        }

        .income {
            color: $color-green;
        }

        .outcome {
            color: $color-red;
        }
    }

    .chart-bubble {
        margin-bottom: 80px;
    }
}
</style>
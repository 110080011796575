<template>
    <layout class="about">
        <h2>Cíl webu</h2>
        <p>
            PolitickeFinance.cz monitoruje financování politických stran a napojení jejich podporovatelů na veřejné finance.
            Cílem je posílit veřejnou kontrolu nad financováním politiky.
        </p>

        <h2>Poděkování</h2>
        <p>
            Web vznikl za podpory platformy Rekonstrukce státu, která pomohla i k prosazení zákonů umožňujících rozsáhlou veřejnou
            kontrolu politických financí. Sběr dat a starší verze webu proběhly za podpory US Embassy Prague a Fondu Otakara Motejla.
        </p>

        <h2>Data</h2>
        <p>
            Všechna data o darech politických stranám, o jejich příjmech, výdajích a dluzích pocházejí z výročních zpráv politických stran.
            Výroční zprávy za období od roku 2017 jsou dostupné na stránkách Úřadu pro dohled nad hospodařením politických stran a hnutí
            (dále ÚDHPSH) - rozcestník zde (https://www.udhpsh.cz/vyrocni-financni-zpravy-stran-a-hnuti). Data před rokem 2017 byla zpracována manuálně
            z výročních zpráv politických stran, proto se jejich rozsah omezuje na desítku největších.
        </p>

        <p>
            Informace o veřejných zakázkách jsou založeny na databázi společnosti Datlab s.r.o., která provozuje rozsáhlou databázi o všech veřejných
            zakázkách v České republice. Data o dotacích poskytnutých jednotlivým dárcům pocházejí z oficiálních webových stránek dotaceeu.cz a szif.cz.
        </p>

        <h2>Často kladené dotazy</h2>

        <template v-for="(n, i) in faq">
            <button v-b-toggle="`faq-${i}`" :key="`q-${i}`" class="question">
                {{ n.q }}
            </button>
            <b-collapse :id="`faq-${i}`" :visible="i == 0" role="tabpanel" :key="`a-${i}`" class="answer">
                {{ n.a }}
            </b-collapse>
        </template>
    </layout>
</template>

<script>
export default {
    name: "About",
    computed: {
        faq: function() {
            return [
                {
                    q: "Jsou na webu data o všech politických stranách?",
                    a: "Na webu PolitickeFinance.cz jsou prezentována data od roku 2017 o všech politických stranách,"
                        + " které mají na webu ÚDHPSH zveřejněny své výroční zprávy ve strojově čitelné podobě (včetně některých dalších stran,"
                        + " které poskytly své výroční zprávy v jiné podobě, zde patří díky ÚDHPSH za dodatečné zpracování dat)."
                },
                {
                    q: "Mohu údaje o své osobě vymazat?",
                    a: "Data vycházejí z oficiálních výročních zpráv, jejich výmaz typicky umožňujeme jen ve skutečně výjimečných případech"
                       + " (pokud se nezmění samotný zdroj)."
                },
                {
                    q: "Proč u dárců fyzických osob nejsou zveřejněny jim zadané zakázky či přidělené dotace?",
                    a: "Data o zakázkách či dotacích jsou navázána na jednotlivé dárce přes jejich IČO. Vzhledem k tomu, že u dárců fyzických osob takový"
                       + " údaj neevidujeme, není možné jim zakázky či dotace přiřadit. Podobně přistupujeme k takovým dárcům - právnickým osobám,"
                       + " u kterých rovněž není IČO uvedené, ani ho není možné dohledat."
                },
                {
                    q: "Z jakého důvodu došlo od roku 2017 k významnému nárůstu počtu zpracovaných stran?",
                    a: "V roce 2017 nebyly účinnosti předpisy nařizující stranám významně zvýšenou míru transparentnosti, a to především ve vztahu"
                       + " k jejich dárcům (včetně poskytovatelů bezúplatných plnění) i účetním datům. To současně znamená, že data od roku 2017 vykazují"
                       + " značně vyšší míru přesnosti a spolehlivosti v porovnání s daty před 2017."
                },
                {
                    q: "Proč u některých stran nejsou dostupná data za roky 2017-2020, ačkoli se nacházejí na webu ÚDHPSH?",
                    a: "Důvodem je zřejmě to, že takové výroční zprávy nejsou zveřejněny ve strojově čitelné strukturované podobě, ale naopak typicky"
                       + " v prostém pdf. Získávání údajů z takových výročních zpráv je bohužel mimo naše možnosti. "
                },
                {
                    q: "Co se stane, pokud má více dárců stejné jméno a příjmení?",
                    a: "Zpracování údajů o dárcích fyzických osobách je obtížné z toho důvodu, že není možné jednoznačně identifikovat konkrétní osobu"
                       + " podle dostupných údajů. Navíc je naší povinností zajistit dostatečnou ochranu jejich osobních údajů. Z toho důvodu na webu"
                       + " prezentujeme výlučně jméno a příjmení dané osoby, interně však různost údaje rozeznáváme s pomocí bezvýznamového indikátoru."
                       + " Pokud má více dárců stejné jméno a příjmení, ale různé datum narození, považujeme je za rozdílné osoby, přičemž každá má svůj"
                       + " profil dárce. V opačném případě, tedy v případě shody jména, příjmení i data narození dárce, přistupujeme ke všem takovým darům"
                       + " jako od jediné osoby."
                }
            ]
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/utils";
@import "src/scss/custom";

.about {

    .question {
        @include colorize-clickable($background-light);
        color: $light;
        font-size: 18px;
        text-align: left;
        border: none;
        display: block;
        width: 100%;
        padding: 15px 25px;
        border-radius: 15px;
    }

    .answer {
        font-size: 16px;
        font-style: italic;
        text-align: left;
        padding-left: 0;
        padding: 15px 25px 15px 50px;
    }

    .question.collapsed:not(:last-child) {
        margin-bottom: 15px;
    }
}

</style>

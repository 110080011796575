import Vue from "vue"

function formatNumber(number, digits = 0) {
    if (number === null) {
        return ""
    }
    if (!number) {
        return "0"
    }
    var formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: digits, minimumFractionDigits: digits });
    return formatter.format(number)
}

function humanizePrice(number, digits=0) {
    if (!number && number !== 0) {
        return ""
    }

    var fnumber
    if (number > 1) {
        var s = ['', 'tis.', 'mil.', 'mld.'];
        var e = Math.floor(Math.log(number) / Math.log(1000));

        fnumber = `${(number / Math.pow(1000, e)).toFixed(digits)} ${s[e]}`
    } else {
        fnumber = number.toFixed(digits)
    }

    return `${fnumber} Kč`;
}

Vue.filter("upper", function(v) {
    if (!v) {
        return ""
    }

    return v.toString().toUpperCase()
})

Vue.filter("lower", function(v) {
    if (!v) {
        return ""
    }

    return v.toString().toLowerCase()
})

Vue.filter("integer", function(number) {
    if (!number) {
        return "0"
    }
    var formatter = new Intl.NumberFormat({ maximumFractionDigits: 0, minimumFractionDigits: 0 });
    return formatter.format(number);
})

Vue.filter("number", function(number, digits = 0) {
    return formatNumber(number, digits)
})

Vue.filter("percentage", function(number, digits = 0) {
    if (!number) {
        number = 0
    }

    return formatNumber(number * 100, digits) + "%";
})

Vue.filter("price", function(number, digits = 0, nbsp = true) {
    if (!number) {
        number = 0
    }

    return formatNumber(number, digits) + (nbsp ? "\xa0" : " ")+ "Kč";
})

Vue.filter("list", function(list, separator = ", ") {
    if (!list || !Array.isArray(list)) {
        return ""
    }

    return list.join(separator)
})

Vue.filter("ico", function(ico) {
    if (!ico || ico.length == 8) {
        return ico
    }

    return ico.padStart(8, '0')
})

Vue.filter("donor_name", function(data) {
    if (data.person == 'PHYSICAL') {
        return `${data.title} ${data.firstname} ${data.lastname} ${data.title_after}`.replaceAll("null", "").trim()
    } else {
        return data.name
    }
})

Vue.filter("humanize_price", function(number, digits = 0) {
    return humanizePrice(number, digits)
})

Vue.filter("humanize_price_for_chart", function(number) {
    if (!number) {
        number = 0
    }
    if (number == 0) {
        return humanizePrice(number)
    } else if (number > 0 && number < 1) {
        return humanizePrice(number, 2)
    } else {
        return humanizePrice(number, 1)
    }
})

Vue.filter("range", function(list, separator = "-") {
    if (!list || !Array.isArray(list)) {
        return ""
    }

    var max = Math.max(...list)
    var min = Math.min(...list)

    return min == max ? `${min}` : `${min}${separator}${max}`
})
<template>
    <layout blank-main-section>
        <grid-row v-if="busy">
            <loader />
        </grid-row>
        <template v-else>
            <grid-row>
                <div class="subtitle">
                    <slot name="subtitle"></slot>
                </div>
                <div class="title">
                    <slot name="title"></slot>
                </div>
            </grid-row>

            <grid-row>
                <slot name="before"></slot>
            </grid-row>

            <grid-row class="tab-buttons">
                <div class="row nav_buttons">
                    <template v-for="(t, i) in tabs">
                        <div
                            v-if="isTabDisabled(i)"
                            :key="`tab-btn-${i}`"
                            :title="$t('disabledTab')"
                            v-b-tooltip.hover.top
                        >
                            <div
                                class="col col-3 col-sm-auto button align-text-bottom"
                                :disabled="isTabDisabled(i)"
                            >
                                {{ t.label }}
                            </div>
                        </div>
                        <div
                            class="col col-3 col-sm-auto button align-text-bottom clickable"
                            v-else
                            :key="`tab-btn-${i}`"
                            :class="{ active: tabIndex == i }"
                            @click="tabIndex = i"
                        >
                            {{ t.label }}
                        </div>
                    </template>
                </div>
            </grid-row>

            <grid-row>
                <b-tabs v-model="tabIndex" lazy>
                    <b-tab v-for="(t, i) in tabs" :key="`tab-content-${i}`" :disabled="isTabDisabled(i)">
                        <slot :name="`tab(${i})`"></slot>
                    </b-tab>
                </b-tabs>
            </grid-row>
        </template>
    </layout>
</template>

<script>
export default {
    name: 'LayoutDetail',
    props: {
        busy: { type: Boolean, default: false },
        tabs: { type: Array, default: () => [] }
    },
    data: function () {
        return {
            tabIndex: 0
        }
    },
    methods: {
        isTabDisabled: function (index) {
            if (Object.prototype.hasOwnProperty.call(this.tabs[index], 'disabled')) {
                return this.tabs[index].disabled
            }

            return false
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/utils';
@import 'src/scss/custom';
@import '~bootstrap/scss/mixins';

.title {
    display: flex;
    align-items: center;
    margin-bottom: 80px;

    & > * {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
    }
}

.subtitle {
    color: $light;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
}

@include media-breakpoint-up(sm) {
    .nav_buttons {
        margin-left: 0px;
    }
}

.tab-buttons {
    margin-bottom: 25px;
    border-bottom: 5px double $background-light;

    @include media-breakpoint-up(sm) {
        margin-bottom: 40px;
    }

    .button {
        text-transform: uppercase;
        background: none;
        border: none;
        color: $body-color;
        font-weight: 500;
        font-size: 16px;
        padding: 8px 0;
        position: relative;

        @include media-breakpoint-up(sm) {
            margin-right: 40px;
        }

        @include media-breakpoint-down(sm) {
            padding: 10px;
            font-size: 14px;
            text-align: center;
        }

        &:not([disabled='disabled']) {
            &.active,
            &:hover {
                background: none;
                border: none;
                color: $body-color;
                font-weight: 700;

                &::before {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 5px;
                    border-bottom: 5px double $dark;
                    bottom: -5px;
                    left: 0;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
            }
        }

        &[disabled='disabled'] {
            color: $light;
        }
    }
}

.tabs {
    .nav-tabs {
        display: none;
    }

    .tab-content .tab-pane {
        padding: 0;
    }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import { CONFIG } from "@/config.js";
import axios from "axios";

const datlabApi = axios.create({
    baseURL: CONFIG.blogDatlab.url,
    params: {key: CONFIG.blogDatlab.key}
});

const api = axios.create({
    baseURL: CONFIG.api.url,
    params: {format: "json"}
    // withCredentials: true,
    // xsrfCookieName: "csrftoken_new",
    // xsrfHeaderName: "X-CSRFToken"
});

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        latestDatlabPost: null,
        topParties: null,
        topDonors: null,
        parties: null,
        donors: null,
        partyYears: [],
        donationYears: [],
        partyNames: [],
        budgetYears: []
    },
    getters: {
        api: () => api
    },
    mutations: {
        setLatestDatlabPost: function(state, post) {
            state.latestDatlabPost = post
        },
        setTopParties: function(state, parties) {
            state.topParties = parties
        },
        setTopDonors: function(state, donors) {
            state.topDonors = donors
        },
        setParties: function(state, parties) {
            state.parties = parties
        },
        setDonors: function(state, donors) {
            state.donors = donors
        },
        setPartyYears: function(state, years) {
            state.partyYears = years
        },
        setDonationYears: function(state, years) {
            state.donationYears = years
        },
        setPartyNames: function(state, names) {
            state.partyNames = names
        },
        setBudgetYears: function(state, years) {
            state.budgetYears = years
        }
    },
    actions: {
        loadLatestDatlabPosts: function({ commit }) {
            return datlabApi.get("posts/", {params: {limit: 2, fields: "title,url,published_at", filter: "tag:politicke-finance"}})
                .then(response => {
                    commit("setLatestDatlabPost", response.data.posts)
                })
                .catch(e => {
                    commit("setLatestDatlabPost", null)
                    console.log(e)
                })
        },
        loadTopParties: function({ commit }) {
            var now = new Date()
            var year = now.getFullYear()
            var month = now.getMonth() + 1
            if (month < 10) {
                month = `0${month}`
            }
            var day = now.getDate()
            if (day < 10) {
                day = `0${day}`
            }
            var isonow = `${year}-${month}-${now.getDate()}`

            var filter_year = year - 1
            if (isonow >= `${year}-01-01` && isonow < `${year}-04-01`) {
                filter_year = year - 2
            }

            var params = {
                limit: 5,
                order_by: "-stats__donations",
                year: filter_year
            }

            return api.get(CONFIG.api.endpoints.party, { params: params })
                .then(response => {
                    commit("setTopParties", response.data.objects)
                })
                .catch(e => {
                    commit("setTopParties", null)
                    console.log(e)
                });
        },
        loadTopDonors: function({ commit }) {
            var params = {
                limit: 5,
                order_by: "-donations",
                min_year: 2016
            }

            return api.get(CONFIG.api.endpoints.donor, { params: params })
                .then(response => {
                    commit("setTopDonors", response.data.objects)
                })
                .catch(e => {
                    commit("setTopDonors", null)
                    console.log(e)
                });
        },
        loadParties: function({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.get(CONFIG.api.endpoints.party, { params: params })
                .then(response => {
                    commit("setParties", response.data.objects)
                    resolve(response)
                })
                .catch(e => {
                    commit("setParties", null)
                    console.log(e)
                    reject(e)
                })
            })
        },
        loadDonors: function({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.get(CONFIG.api.endpoints.donor, { params: params })
                .then(response => {
                    commit("setDonors", response.data.objects)
                    resolve(response)
                })
                .catch(e => {
                    commit("setDonors", null)
                    console.log(e)
                    reject(e)
                })
            })
        },
        loadPartyYears: function({ commit }) {
            return api.get("party_years")
                .then(response => {
                    commit("setPartyYears", response.data)
                })
                .catch(e => {
                    commit("setPartyYears", [])
                    console.log(e)
                });
        },
        loadDonationYears: function({ commit }, options = {}) {
            return api.get("donation_years", options)
                .then(response => {
                    commit("setDonationYears", response.data)
                })
                .catch(e => {
                    commit("setDonationYears", [])
                    console.log(e)
                });
        },
        loadBudgetYears: function({ commit }, party) {
            return api.get("budget_years", {params: {party: party}})
                .then(response => {
                    commit("setBudgetYears", response.data)
                })
                .catch(e => {
                    commit("setBudgetYears", [])
                    console.log(e)
                });
        }
    },
    modules: {
    }
})

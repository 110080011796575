import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./messages"
import "./filters"
import axios from "axios"
import VueAxios from "vue-axios";
import VueMoment from "vue-moment"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import Multiselect from 'vue-multiselect'
import VueGtm from '@gtm-support/vue2-gtm'

import Icon from "@/components/Icon.vue"
import Loader from "@/components/Loader.vue"
import Base from '@/views/layout/Base.vue'
import GridRow from '@/components/GridRow.vue'
import Detail from '@/views/layout/Detail.vue'
import VueGoogleCharts from 'vue-google-charts'

import {CONFIG} from './config.js'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAxios, axios)
Vue.use(VueMoment)
Vue.use(VueGoogleCharts)

if (CONFIG.gtmkey) {
    Vue.use(VueGtm, {
        id: CONFIG.gtmkey,
        vueRouter: router,
        enabled: !!CONFIG.gtmkey
    });
}

Vue.component('multiselect', Multiselect)
Vue.component('icon', Icon)
Vue.component('loader', Loader)
Vue.component('layout', Base)
Vue.component('grid-row', GridRow)
Vue.component('layout-detail', Detail)

Vue.prototype.$getSelectedOptionValue = function(option) {
  if (option === null || option === undefined) {
      return null
  }

  return Object.prototype.hasOwnProperty.call(option, "value") ? option.value : option
}


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

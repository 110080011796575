<template>
    <div class="detail-tab">
        <div class="board" v-if="bubbleData">
            <ContentBubble icon="finance" variant="success">
                <div v-html="bubbleHtml"></div>
            </ContentBubble>
        </div>

        <h2>{{ $t('donor.tenders.topFive') }}</h2>

        <DataTable class="top-tenders" :data="tenders" :keys="tableKeys" :hoverable="false" :is-busy="busy" simple>
            <template #cell(title)="{ data: n }">
                <div class="col col-6 col-md-4 header_row">
                    <div class="name">{{ n.title }}</div>
                    <div>
                        <span class="value">{{ n.year }}</span>
                        <div class="d-block d-md-none">
                            <div class="label">{{ $t('donor.tenders.buyer') }}:</div>
                            <div class="value">{{ n.buyer }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(buyer)="{ item: n }">
                <div class="col d-none d-md-block col-md-4">
                    <div class="label">{{ $t('donor.tenders.buyer') }}:</div>
                    <div class="value">{{ n }}</div>
                </div>
            </template>
            <template #cell(price)="{ item: n }">
                <div class="col col-6 col-md-4 total">
                    <div class="total">
                        <template v-if="n === null">
                            {{ $t('noPrice') | lower }}
                        </template>
                        <template v-else>
                            {{ n | price(0) }}
                        </template>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import ContentBubble from '@/components/ContentBubble.vue'

import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        DataTable,
        ContentBubble
    },
    data: function () {
        return {
            busy: false,
            tenders: null,
            bubbleData: null
        }
    },
    computed: {
        tableKeys: function () {
            return [{ id: 'title' }, { id: 'buyer' }, { id: 'price' }]
        },
        bubbleHtml: function () {
            if (!this.bubbleData) {
                return ''
            }

            var params = {
                company: this.$options.filters.donor_name(this.data),
                count: this.bubbleData.count,
                amount: this.$options.filters.humanize_price(this.bubbleData.amount),
                top_count: this.bubbleData.count,
                top_name: this.bubbleData.name,
                tenders_link: 'https://tenderman.cz/',
                redflags_link: 'https://kontrola.datlab.eu/'
            }

            if (this.bubbleData.min_year == this.bubbleData.max_year) {
                return this.$t('html.tendersBubbleAlt', { ...params, ...{ year: this.bubbleData.min_year } })
            } else {
                return this.$t('html.tendersBubble', {
                    ...params,
                    ...{ min_year: this.bubbleData.min_year, max_year: this.bubbleData.max_year }
                })
            }
        }
    },
    created: function () {
        this.loadTenders()
        this.loadTendersBubble()
    },
    methods: {
        loadTendersBubble: function () {
            var params = {
                donor: this.data.id
            }

            this.$store.getters.api
                .get('tenders_bubble', { params: params })
                .then((response) => {
                    this.bubbleData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.bubbleData = null
                })
        },
        loadTenders: function () {
            var params = {
                limit: 5,
                order_by: '-price',
                bidder: this.data.id
            }

            this.busy = true

            this.$store.getters.api
                .get(`${CONFIG.api.endpoints.tender}`, { params: params })
                .then((response) => {
                    this.tenders = response.data.objects
                })
                .catch((e) => {
                    this.tenders = null
                    console.log(e)
                })
                .finally(() => (this.busy = false))
        }
    }
}
</script>

<style scoped lang="scss">
.board .row:not(:last-child) {
    margin-bottom: 30px;
}
</style>
<template>
    <div class="detail-tab">
        <div class="board" v-if="bubbleData">
            <ContentBubble icon="finance" variant="success">
                <div
                    v-html="
                        $t('html.eusubsBubble', {
                            company: donorName,
                            min_year: bubbleData.min_year,
                            max_year: bubbleData.max_year,
                            count: bubbleData.count,
                            amount: $options.filters.humanize_price(bubbleData.total)
                        })
                    "
                ></div>
            </ContentBubble>
        </div>

        <h2>{{ $t('donor.eusubs.topFive') }}</h2>

        <DataTable class="top-eusubs" :data="subs" :keys="tableKeys" :hoverable="false" :is-busy="busy" simple>
            <template #cell(name)="{ item: n, data: d }">
                <div class="col col-6 col-md-5 header_row">
                    <div class="name">{{ n }}</div>
                    <span class="value d-block d-md-none">{{ d.year }}</span>
                </div>
            </template>
            <template #cell(year)="{ item: n }">
                <div class="col d-none d-md-block col-md-2">
                    <template v-if="n">
                        <span class="label">{{ $t('donor.eusubs.year') }}: </span>
                        <span class="value">{{ n }}</span>
                    </template>
                </div>
            </template>
            <template #cell(amount)="{ item: n }">
                <div class="col col-6 col-md-5 total">
                    {{ n | price(0) }}
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import ContentBubble from '@/components/ContentBubble.vue'

import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        DataTable,
        ContentBubble
    },
    data: function () {
        return {
            busy: false,
            subs: null,
            bubbleData: null
        }
    },
    computed: {
        tableKeys: function () {
            return [{ id: 'name' }, { id: 'year', class: 'col-auto' }, { id: 'amount' }]
        },
        donorName: function () {
            return this.$options.filters.donor_name(this.data)
        }
    },
    created: function () {
        this.loadSubsBubble()
        this.loadSubs()
    },
    methods: {
        loadSubsBubble: function () {
            var params = {
                donor: this.data.id
            }

            this.$store.getters.api
                .get('eusubs_bubble', { params: params })
                .then((response) => {
                    this.bubbleData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.bubbleData = null
                })
        },
        loadSubs: function () {
            var params = {
                limit: 5,
                order_by: '-amount',
                company: this.data.id
            }

            this.busy = true

            this.$store.getters.api
                .get(`${CONFIG.api.endpoints.eusub}`, { params: params })
                .then((response) => {
                    this.subs = response.data.objects
                })
                .catch((e) => {
                    this.subs = null
                    console.log(e)
                })
                .finally(() => (this.busy = false))
        }
    }
}
</script>

<style scoped lang="scss">
.board .row:not(:last-child) {
    margin-bottom: 30px;
}
</style>
<template>
    <div v-if="news && news.length" class="blog-posts">
        <h5><icon icon="news" /> {{ $t('home.latestNews') }}</h5>

        <a class="post" v-for="n in news" :key="n.url" v-bind:href="n.url" target="_blank">
            <h6>
                <span class="date">{{ n.published_at | moment('YYYY-MM-DD') }}</span> {{ n.title }}
            </h6>
        </a>
    </div>
</template>

<script>
export default {
    computed: {
        news: function() {
            return this.$store.state.latestDatlabPost
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";
@import "src/scss/utils";
@import "~bootstrap/scss/mixins";

.blog-posts {
    @include colorize-clickable($background-light);
    @include make-rounded();

    padding: 24px 42px;
    text-align: left;
    margin-bottom: 0;
    display: inline-block;

    a {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: $body-color;
        font-size: 18px;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        &:hover {
            color: $body-color;

            h6 {
                text-decoration: underline;
            }
        }
    }

    h5 {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    h6 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        display: inline-block;
        margin-right: 15px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }

        .date {
            color: $light;
            font-family: $font-family-monospace;
            display: inline-block;
            margin-right: 20px;
        }
    }
}
</style>
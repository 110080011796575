<template>
    <img :src="`${baseUrl}images/${file}`" role="img" alt="icon" />
</template>

<script>
export default {
    name: "icon",
    props: {
        icon: {type: String, required: true}
    },
    computed: {
        baseUrl: function() {
            return process.env.BASE_URL
        },
        mapping: function() {
            return {
                news: "news.svg",
                dbl_arrow: "double_arrow.svg",
                datlab: "datlab_logo.svg",
                d: "footer_d.svg",
                polfin: "polfin_logo.svg",
                disclaimer: "disclaimer.svg",
                gift_in: "gift_in.svg",
                gift_out: "gift_out.svg",
                search: "search.svg",
                person: "person.svg",
                party: "party.svg",
                company: "company.svg",
                arrow_down: "arrow_down.svg",
                fhealth: "financial_health.svg",
                pie_chart: "pie_chart.svg",
                warn: "warning.svg",
                count: "count.svg",
                sum: "sum.svg",
                calendar: "period.svg",
                warn_triangle: "warning_triangle.svg",
                shield: "shield.svg",
                methodology: "methodology.svg",
                line_chart: "line_chart.svg",
                bubble_chart: "bubble_chart.svg",
                map: "map.svg",
                finance: "finance.svg",
                empty: "empty.svg",
                rest: "rest-frank-zajistuje-horiz.png"
            }
        },
        file: function() {
            if (this.icon in this.mapping) {
                return this.mapping[this.icon]
            }

            return null
        }
    }

}
</script>

<style>

</style>
<template>
    <component :is="element" class="donor-name" :class="{ name: !simple && data.person == 'PHYSICAL' }">
        <template v-if="simple || data.person == 'PHYSICAL'">
            {{ data | donor_name }}
            <div class="extras" v-if="data && !simple">
                <slot name="extras" :data="data"></slot>
            </div>
        </template>
        <template v-else>
            <div class="name">{{ data.name }}</div>
            <div class="extras">
                <slot name="extras" v-if="data.ico" :data="data">
                    <template v-if="data.ico">
                        <span class="label">{{ $t('home.donor.vatId') | upper }}: </span>
                        <span class="value">{{ data.ico }}</span>
                    </template>
                </slot>
            </div>
        </template>
    </component>
</template>

<script>
export default {
    props: {
        data: { type: Object, required: true },
        simple: { type: Boolean, default: false },
        element: { type: String, default: 'div' }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.donor-name .extras {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;

    .label {
        color: $light;
    }

    .value + .label {
        margin-left: 15px;
    }
}
</style>
<template>
    <b-dropdown no-caret :right="right" size="md" toggle-tag="div" class="filter-dropdown" :toggle-class="{ bordered: bordered }" no-flip>
        <template v-slot:button-content>
            <span v-if="label">{{ label }}: </span>
            <slot name="selected" :option="selectedOption" :label="getOptionLabel(selectedOption)">
                {{ getOptionLabel(selectedOption) }}
            </slot>
        </template>

        <b-dropdown-item v-for="(n, i) in options" :key="`option-${i}`" :active="selectedOption == n" @click="selectOption(n)">
            <slot name="option" :option="n" :label="getOptionLabel(n)">{{ getOptionLabel(n) }}</slot>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    model: {
        prop: 'selected',
        event: 'option-selected'
    },
    props: {
        label: { type: String },
        selected: { type: [Object, Number, String] },
        options: { type: Array, required: true },
        bordered: { type: Boolean, required: false },
        right: { type: Boolean, required: false }
    },
    data: function () {
        return {
            selectedOption: this.selected
        }
    },
    watch: {
        selected: function () {
            this.selectedOption = this.selected
        }
    },
    computed: {},
    methods: {
        selectOption: function (selected) {
            this.selectedOption = selected
            this.$emit('option-selected', this.selectedOption)
        },
        getOptionLabel: function (option) {
            if (option === null || option === undefined) {
                return ''
            }

            return Object.prototype.hasOwnProperty.call(option, 'label') ? option.label : option
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import '~bootstrap/scss/mixins';

.filter-dropdown {
    .dropdown-toggle {
        background: none !important;
        border: none !important;
        color: $dark !important;
        font-size: 16px;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        &.bordered {
            border: solid 1px $light !important;
            margin: 2px;
            padding: 15px 25px 15px 25px;
            border-radius: 15px;

            &:hover {
                border: solid 3px $dark !important;
                margin: 0;
            }

            @include media-breakpoint-down(sm) {
                padding: 5px 10px 5px 10px;
            }
        }

        &:focus {
            box-shadow: none;
        }

        span {
            color: $light;
        }
    }

    &.show .dropdown-toggle.bordered {
        border: solid 3px $dark !important;
        margin: 0;
    }

    .dropdown-menu {
        border-radius: 15px;
        border: solid 3px $dark;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        max-width: 500px;
        max-height: 300px;
    }

    .dropdown-item {
        padding-left: 40px;
        padding-right: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dropdown-item:hover,
    .dropdown-item.active {
        background: none;
        color: $dark;
    }

    li[role='presentation'] {
        position: relative;

        .dropdown-item:hover::before,
        .dropdown-item.active::before {
            content: ' ';
            position: absolute;
            display: block;
            width: 80px;
            height: 20px;
            background: url('../../public/images/double_arrow.svg') 0 0 no-repeat;
            top: 6px;
            left: -20px;
        }
    }
}
</style>
<template>
    <ContentBubble class="chart-bubble">
        <template #title>
            <b-row class="chart-title">
                <b-col><icon :icon="icon" /> <small>{{ title }}</small></b-col>
                <b-col v-if="legend">
                    <b-row class="legend" :class="legendClasses">
                        <b-col v-for="(l, i) in legend" :key="`legend-${i}`" cols="auto"><i :style="{backgroundColor: l.color}" /> {{ l.label }}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>

        <slot></slot>
    </ContentBubble>
</template>

<script>
import ContentBubble from "@/components/ContentBubble.vue"

export default {
    props: {
        title: {type: String},
        legend: {type: Array},
        icon: {type: String, default: "line_chart"},
        legendAlign: {type: String, default: "right"}
    },
    components: {
        ContentBubble
    },
    computed: {
        legendClasses: function() {
            return {
                'justify-content-start': this.legendAlign == 'left',
                'justify-content-end': this.legendAlign == 'right'
            }
        }
    }
}
</script>

<style lang="scss">
.chart-bubble {
    .chart-title {
        margin-bottom: 20px;

        .legend {
            font-size: 12px;
            justify-content: flex-end;

            i {
                vertical-align: middle;
                width: 12px;
                height: 12px;
                border-radius: 4px;
                display: inline-block;
            }
        }
    }

    .bubble-content {
        font-size: 12px;
        line-height: 1;
    }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"home text-center",attrs:{"blog-footer":false}},[_c('h1',[_vm._v(_vm._s(_vm.$t('home.title')))]),_c('div',{staticClass:"search"},[_c('div',{staticClass:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('home.search.label'))}})]),_c('multiselect',_vm._b({on:{"search-change":_vm.getSearchOptions,"select":function (o) { return _vm.goToDetail(o); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var o = ref.option;
return [(o.$isLabel)?[_c('strong',[_vm._v(_vm._s(_vm.$t(("home.search." + (o.$groupLabel)))))])]:[_c('div',{staticClass:"d-flex justify-content-between"},[(o.person == 'PHYSICAL')?[_c('div',{staticClass:"name"},[_vm._v(_vm._s(o.title)+" "+_vm._s(o.firstname)+" "+_vm._s(o.lastname)+" "+_vm._s(o.title_after))])]:[_c('div',{staticClass:"name"},[_vm._v(_vm._s(o.name))]),(o.ico)?_c('div',{staticClass:"ico"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm._f("upper")(_vm.$t('home.donor.vatId')))+": ")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("ico")(o.ico)))])]):_vm._e()]],2)]]}},{key:"noResult",fn:function(){return [(_vm.isSearchOptionsLoading)?[_vm._v(" "+_vm._s(_vm.$t('home.search.loader'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('home.search.noData'))+" ")]]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('home.search.noOptions'))+" ")]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'multiselect',_vm.searchSettings,false))],1),_c('BlogPosts',{staticClass:"news"}),_c('div',{staticClass:"parties"},[_c('div',{staticClass:"content-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('home.parties')))]),_c('router-link',{attrs:{"to":{ name: 'parties' }}},[_vm._v(_vm._s(_vm.$t('home.allParties'))+" "),_c('icon',{attrs:{"icon":"dbl_arrow"}})],1)],1),_c('DataTable',{attrs:{"data":_vm.topParties,"keys":_vm.partiesTableKeys,"is-busy":_vm.isTopPartiesLoading,"simple":""},on:{"row-clicked":function (n) { return _vm.$router.push({ name: 'party-detail', params: { slug: n.slug } }); }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col col-6 col-md-4 header_row"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col col-icon d-none d-sm-block"},[_c('div',{staticClass:"logo"},[_c('icon',{attrs:{"icon":"party"}})],1)]),_c('div',{staticClass:"col"},[_c('PartyName',{attrs:{"data":n}})],1)])])]}},{key:"cell(income)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2 text-right text-sm-left"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.income'))+":")]),_c('div',{staticClass:"value income"},[_vm._v("+ "+_vm._s(_vm._f("price")((n / 1000),0)))])])]}},{key:"cell(outcome)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2 text-right text-sm-left"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.outcome'))+":")]),_c('div',{staticClass:"value outcome"},[_vm._v("- "+_vm._s(_vm._f("price")((n / 1000),0)))])])]}},{key:"cell(donations)",fn:function(ref){
var n = ref.item;
var d = ref.data;
return [_c('div',{staticClass:"col col-6 col-md-4 total"},[_c('icon',{attrs:{"icon":"gift_in"}}),_vm._v(_vm._s(_vm._f("price")(n,0))+" "),_c('div',{staticClass:"d-block d-md-none sub_block"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.income'))+":")]),_c('div',{staticClass:"value income"},[_vm._v("+ "+_vm._s(_vm._f("price")((d.income / 1000),0)))]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('party.outcome'))+":")]),_c('div',{staticClass:"value outcome"},[_vm._v("- "+_vm._s(_vm._f("price")((d.outcome / 1000),0)))])])],1)]}}])})],1),_c('div',{staticClass:"donors"},[_c('div',{staticClass:"content-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('home.donors')))]),_c('router-link',{attrs:{"to":{ name: 'donors' }}},[_vm._v(_vm._s(_vm.$t('home.allDonors'))+" "),_c('icon',{attrs:{"icon":"dbl_arrow"}})],1)],1),_c('DataTable',{attrs:{"data":_vm.topDonors,"keys":_vm.donorsTableKeys,"is-busy":_vm.isTopDonorsLoading,"simple":""},on:{"row-clicked":function (n) { return _vm.$router.push({ name: 'donor-detail', params: { slug: n.slug } }); }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col col-5 col-md-4 header_row"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col col-icon d-none d-sm-block"},[_c('div',{staticClass:"logo"},[_c('icon',{attrs:{"icon":n.person == 'PHYSICAL' ? 'person' : 'company'}})],1)]),_c('div',{staticClass:"col"},[_c('DonorName',{attrs:{"data":n}})],1)])])]}},{key:"cell(parties)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2 text-left"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.parties'))+":")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatParties(n)))])])]}},{key:"cell(risks)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2 text-right"},[(n.eusubs)?[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.risks.label'))+":")]),_c('div',{staticClass:"value risks"},[_vm._v(_vm._s(_vm._f("list")(_vm.getDonorRisks(n))))])]:_vm._e()],2)]}},{key:"cell(donations)",fn:function(ref){
var n = ref.item;
var d = ref.data;
return [_c('div',{staticClass:"col col-7 col-md-4 total"},[_c('icon',{attrs:{"icon":"gift_out"}}),_vm._v(_vm._s(_vm._f("price")(n,0))+" "),_c('div',{staticClass:"d-block d-md-none sub_block"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.parties'))+":")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatParties(d.parties)))])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
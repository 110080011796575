export default {
    computed: {
        defaultChartOptions: function() {
            return {
                bar: { groupWidth: 11 },
                legend: { position: "none" },
                backgroundColor: "transparent",
                fontName: "Inter",
                fontSize: 14,
                enableInteractivity: false,
                chartArea: {
                    height: '100%',
                    width: '100%',
                    top: 20,
                    left: 0,
                    right: 50,
                    bottom: 30
                },
                vAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                    textStyle: {
                        color: "gray"
                    },
                    viewWindowMode: "maximized"
                },
                hAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                    textStyle: {
                        color: "gray"
                    },
                    slantedText: false
                },
                vAxes: {
                    0: { textPosition: 'none' },
                    1: {}
                }
            }
        }
    },
    methods: {
        getYearAxisTicks: function(years) {
            if (!years) {
                return []
            }

            var min = Math.min(...years)
            var max = Math.max(...years)

            var hticks = []
            for (var y = min; y <= max; y++) {
                hticks.push({v: y, f: `${y}`.substring(2)})
            }

            return hticks
        }
    }
}
<template>
    <div class="detail-tab">
        <div class="board">
            <b-row>
                <b-col class="mb-3 col col-12 col-sm-6 col-lg-3 order-1">
                    <ValueBubble icon="count" :title="$t('party.donos.count')"> #{{ data.donations_count_total }} </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-12 col-sm-12 col-lg-6 order-3 order-lg-2">
                    <ValueBubble icon="sum" :title="$t('party.donos.sum')">
                        <icon icon="gift_in" width="16" class="align-top" />{{ data.donations_amount_total | price(0) }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-12 col-sm-6 col-lg-3 order-2 order-lg-3">
                    <ValueBubble icon="calendar" :title="$t('party.donos.period')">
                        {{ data.donations_min_year }}-{{ data.donations_max_year }}
                    </ValueBubble>
                </b-col>
            </b-row>
        </div>

        <h2>{{ $t('party.donos.byYearAndDonor') }}</h2>
        <DataTable
            class="donos-by-year-and-donor"
            :data="donos"
            :keys="donosTableKeys"
            :is-busy="donosBusy"
            :per-page="selectedPageSize"
            :total-rows="totalRows"
            :page="currentPage"
            @page-change="(page) => (currentPage = page)"
            @row-clicked="(n) => $router.push({ name: 'donor-detail', params: { slug: n.slug } })"
            @reset-filter="resetFilter"
        >
            <template #action-bar-left>
                <FilterMultiselect
                    class="donor"
                    v-model="selectedDonors"
                    :loader="getDonorOptions"
                    :label="$t('donor.label')"
                    :placeholder="$t('filter.allAlt2')"
                >
                    <template #option="{ option }">
                        <DonorName :data="option" simple class="text-truncate" />
                    </template>

                    <template #tag="{ option }">
                        <DonorName :data="option" simple element="span" />
                    </template>

                    <template #singleValue="{ value }">
                        <DonorName :data="value" simple element="span" class="value" />
                    </template>

                    <template #multipleValue="{ values }">
                        {{ $tc('donor.pagination.selected', values.length) }}
                    </template>
                </FilterMultiselect>

                <FilterDropdown class="year" :bordered="true" :label="$t('donor.year')" :options="yearOptions" v-model="selectedYear">
                </FilterDropdown>

                <FilterDropdown
                    class="donation-type"
                    :bordered="true"
                    :label="$t('donation.type.label')"
                    :options="donationTypeOptions"
                    v-model="selectedDonationType"
                >
                </FilterDropdown>

                <FilterDropdown
                    class="type"
                    :bordered="true"
                    :label="$t('donor.risks.filterLabel')"
                    :options="riskTypeOptions"
                    v-model="selectedRiskType"
                >
                </FilterDropdown>
            </template>

            <template #action-bar-right>
                <FilterDropdown class="sorting" :label="$t('sorting.label')" :options="sortingOptions" v-model="selectedSortOption" right>
                    <template #selected="data"> {{ data.label }} <icon icon="arrow_down" /> </template>
                </FilterDropdown>
            </template>

            <template #cell(logo)="{ data: n }">
                <div class="logo">
                    <icon :icon="n.person == 'PHYSICAL' ? 'person' : 'company'" />
                </div>
            </template>
            <template #cell(name)="{ data: n, data: d }">
                <div class="col col-5 col-sm-6 col-md-4 header_row">
                    <div class="row align-items-center">
                        <div class="col col-icon d-none d-sm-block">
                            <div class="logo">
                                <icon :icon="n.person == 'PHYSICAL' ? 'person' : 'company'" />
                            </div>
                        </div>
                        <div class="col">
                            <DonorName :data="n" />
                            <template v-if="isDonorRisky(d)">
                                <span class="label">{{ $t('donor.risks.label') }}:</span>
                                <span class="value risks"> {{ getDonorRisks(d) | list }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(donation_types)="{ item: n }">
                <div class="col d-none d-md-block col-md-2">
                    <div class="label">{{ $t('donation.type.label') }}:</div>
                    <div class="value">{{ getDonationTypes(n) | list }}</div>
                </div>
            </template>
            <template #cell(year)="{ item: n }">
                <div class="col d-none d-md-block col-md-2">
                    <div class="label">{{ $t('party.year') }}:</div>
                    <div class="value" v-if="selectedYear.value === null">{{ n | range }}</div>
                    <div class="value" v-else>{{ $getSelectedOptionValue(selectedYear) }}</div>
                </div>
            </template>
            <template #cell(donations)="{ item: n, data: d }">
                <div class="col col-7 col-sm-6 col-md-4 total">
                    <icon icon="gift_out" />{{ n | price(0) }}
                    <div class="d-block d-sm-none">
                        <div class="value">{{ getDonationTypes(d.donation_types) | list }}</div>
                        <div class="value" v-if="selectedYear.value === null">{{ d.year | range }}</div>
                        <div class="value" v-else>{{ $getSelectedOptionValue(selectedYear) }}</div>
                    </div>
                </div>
            </template>
        </DataTable>

        <h2>{{ $t('party.donos.byYear.label') }}</h2>
        <DataTable class="donos-by-year" :data="data.donations_by_year" :keys="donosByYearTableKeys" :hoverable="false" simple>
            <template #cell(year)="{ item: n, data: d }">
                <div class="col col-2 col-sm-1 text-center">
                    {{ n }}
                    <div class="d-block d-sm-none">
                        <icon v-if="verifyDonations(d.donations, d.checksum)" icon="shield" />
                        <icon v-else icon="warn_triangle" />
                    </div>
                </div>
            </template>
            <template #cell(donations)="{ data: d }">
                <div class="col col-9 col-sm-5 check">
                    <span class="label">{{ $t('party.donos.byYear.amount') }}: </span>{{ d.donations | price(0) }}
                    <span class="d-block d-sm-none">
                        <br /><span class="label">{{ $t('party.donos.byYear.check') }}: </span>{{ d.checksum | price(0) }}
                    </span>
                </div>
            </template>
            <template #cell(checksum)="{ item: n }">
                <div class="col col-9 col-sm-5 d-none d-sm-block check">
                    <span class="label">{{ $t('party.donos.byYear.check') }}: </span>{{ n | price(0) }}
                </div>
            </template>
            <template #cell(icon)="{ data: n }">
                <div class="col col-1 d-none d-sm-block">
                    <icon v-if="verifyDonations(n.donations, n.checksum)" icon="shield" />
                    <icon v-else icon="warn_triangle" />
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import ValueBubble from '@/components/ValueBubble.vue'
import DataTable from '@/components/DataTable.vue'
import DonorName from '@/components/DonorName.vue'
import FilterDropdown from '@/components/FilterDropdown.vue'
import FilterMultiselect from '@/components/FilterMultiselect.vue'

import donorMixin from '@/plugins/donorMixin.js'

import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        ValueBubble,
        DataTable,
        DonorName,
        FilterDropdown,
        FilterMultiselect
    },
    mixins: [donorMixin],
    data: function () {
        return {
            donos: null,
            donosBusy: false,
            totalRows: 0,
            currentPage: 1,
            selectedPageSize: 10,
            selectedDonorType: null,
            selectedDonationType: null,
            selectedYear: null,
            selectedSortOption: null,
            selectedRiskType: null,
            selectedDonors: []
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.currentPage = 1
            }
        },
        params: {
            deep: true,
            handler: function () {
                this.loadDonos(this.params)
            }
        }
    },
    computed: {
        donosByYearTableKeys: function () {
            return [{ id: 'year' }, { id: 'donations' }, { id: 'checksum' }, { id: 'icon' }]
        },
        donosTableKeys: function () {
            return [{ id: 'name' }, { id: 'donation_types' }, { id: 'risks' }, { id: 'year' }, { id: 'donations' }]
        },
        sortingOptions: function () {
            return [
                { label: this.$t('donation.sorting.options.dono'), value: '-donations' },
                { label: this.$t('donation.sorting.options.year'), value: '-year' }
            ]
        },
        filters: function () {
            var map = {
                year: this.selectedYear,
                donor_type: this.selectedDonorType,
                donation_type: this.selectedDonationType,
                risk_type: this.selectedRiskType,
                donors: this.selectedDonors.length ? this.selectedDonors.map((n) => n.id).join(',') : null
            }

            var filters = {}
            for (const f in map) {
                var val = this.$getSelectedOptionValue(map[f])
                if (val !== null) {
                    filters[f] = val
                }
            }

            return filters
        },
        params: function () {
            return {
                ...{
                    limit: this.selectedPageSize,
                    offset: (this.currentPage - 1) * this.selectedPageSize,
                    order_by: this.$getSelectedOptionValue(this.selectedSortOption),
                    party: this.data.id
                },
                ...this.filters
            }
        },
        yearOptions: function () {
            return [{ label: this.$t('filter.allAlt'), value: null }].concat(this.$store.state.donationYears)
        },
        donorTypeOptions: function () {
            return [
                { label: this.$t('filter.allAlt'), value: null },
                { label: this.$t('donor.type.physical'), value: 'PHYSICAL' },
                { label: this.$t('donor.type.juridical'), value: 'JURIDICAL' }
            ]
        },
        donationTypeOptions: function () {
            return [
                { label: this.$t('filter.allAlt'), value: null },
                { label: this.$t('donation.type.options.donation'), value: 'DONATION' },
                { label: this.$t('donation.type.options.bup'), value: 'BUP' },
                { label: this.$t('donation.type.options.overlimit'), value: 'OVER_LIMIT' }
            ]
        }
    },
    created: function () {
        this.$store.dispatch('loadDonationYears', {params: {party: this.data.id}}).then(() => this.resetFilter())
    },
    methods: {
        resetFilter: function () {
            this.selectedSortOption = this.sortingOptions[0]
            this.selectedYear = this.yearOptions[0]
            this.selectedDonorType = this.donorTypeOptions[0]
            this.selectedDonationType = this.donationTypeOptions[0]
            this.selectedRiskType = this.riskTypeOptions[0]
        },
        loadDonos: function (params) {
            return new Promise((resolve, reject) => {
                this.donosBusy = true

                this.$store.getters.api
                    .get(`${CONFIG.api.endpoints.donation_by_donor}`, { params: params })
                    .then((response) => {
                        this.totalRows = response.data.meta.total_count
                        this.donos = response.data.objects
                        resolve()
                    })
                    .catch((e) => {
                        this.donos = null
                        console.log(e)
                        reject(e)
                    })
                    .finally(() => (this.donosBusy = false))
            })
        },
        getDonationTypes: function (types) {
            return types.map((t) => {
                var tmp = this.donationTypeOptions.find((n) => n.value == t)
                return tmp ? tmp.label : t
            })
        },
        getDonorOptions: function (search) {
            return new Promise((resolve, reject) => {
                var params = {
                    q: search,
                    party: this.data.id
                }

                this.$store.getters.api
                    .get('donor_options', { params: params })
                    .then((response) => resolve(response.data))
                    .catch((e) => reject(e))
            })
        },
        verifyDonations: function (donations, checksum) {
            // diff < 0.1%
            return (Math.abs(donations - checksum) / (donations + checksum) / 2) * 100 < 0.1
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.donos-by-year {
    .year {
        font-size: 18px;
        font-weight: 600;
        color: $body-color;
    }

    .amount,
    .check {
        font-size: 14px;
        font-weight: 500;
        color: $body-color;
        font-family: $font-family-monospace;

        .label {
            font-weight: 400;
            color: $light;
            font-family: $font-family-sans-serif;
        }
    }
}

.donos-by-year-and-donor {
    margin-bottom: 90px;
}
</style>
<template>
    <div class="loader">
        <b-icon icon="three-dots" animation="fade" font-scale="2"></b-icon>
    </div>
</template>

<script>
export default {
    name: "loader"
}
</script>

<style lang="scss" scoped>
.loader {
    display: block;
    margin: 0 auto;
}
</style>
<template>
    <div>
        <header>
            <div class="container-fluid">
                <nav class="grid-row nav">
                    <div class="grid-col">
                        <b-navbar toggleable="lg">
                            <b-navbar-brand :to="{ name: 'home' }" class="logo">
                                <icon icon="datlab" />
                                <div>{{ $t('polfin') | upper }}</div>
                            </b-navbar-brand>

                            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                            <b-collapse id="nav-collapse" is-nav>
                                <b-navbar-nav class="ml-auto">
                                    <b-nav-item
                                        v-for="(n, k) in nav"
                                        :key="`nav-${k}`"
                                        exact
                                        exact-active-class="active"
                                        active-class="active"
                                        v-bind="n"
                                    >
                                        {{ $t(`nav.${k}`) | upper }}
                                    </b-nav-item>
                                </b-navbar-nav>
                            </b-collapse>
                        </b-navbar>
                    </div>
                </nav>

                <div class="grid-row no-gutters site-description">
                    <div class="grid-col text-center"></div>
                </div>
            </div>
        </header>

        <main>
            <slot v-if="blankMainSection"></slot>
            <grid-row v-else>
                <slot></slot>
            </grid-row>
        </main>

        <footer id="latest-blog-post" v-if="blogFooter && news">
            <grid-row centered>
                <BlogPosts />
            </grid-row>
        </footer>

        <footer id="claim" class="text-left">
            <div class="line-logo">
                <icon icon="polfin" />
            </div>

            <grid-row>
                <div class="row">
                    <div class="col">
                        <div class="icon">
                            <a href="https://datlabinstitut.cz" target="_blank" class="mr-3">
                                <icon icon="datlab" />
                                <div>{{ $t('footer.logo') | upper }}</div>
                            </a>
                            <a href="https://www.rekonstrukcestatu.cz" target="_blank">
                                <icon icon="rest" :height="36" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-5 col-xl-4 claim">
                        <p>{{ $t('footer.claim.0') }}</p>
                        <p>{{ $t('footer.claim.1') }}</p>
                    </div>

                    <div class="col-auto contact">
                        <h4>{{ $t('footer.contact') }}</h4>
                        <p class="address">
                            {{ $t('footer.address.name') }}<br />
                            {{ $t('footer.address.street') }}<br />
                            {{ $t('footer.address.city') }}<br />
                            {{ $t('footer.address.country') }}<br />
                        </p>
                        <div class="mb-0 vat_id">
                            <div><a href="https://datlabinstitut.cz">datlabinstitut.cz</a></div>
                        </div>
                    </div>

                    <div class="col-auto links">
                        <h4>{{ $t('projects') | upper }}</h4>
                        <a href="https://www.zindex.cz/" target="_blank">Zindex.cz</a>
                        <a href="https://kontrola.datlab.eu/" target="_blank">Kontrola rizik</a>
                        <a href="https://tenderman.cz/" target="_blank">Tenderman.cz</a>
                    </div>

                    <div class="col-auto others">
                        <h4>{{ $t('footer.others.label') | upper }}</h4>
                        <a href="https://datlab.eu/blog/" target="_blank">Blog</a>
                        <a href="https://www.facebook.com/datlab.cz" target="_blank">Facebook</a>
                        <a href="https://datlab.eu/blog/#subscribe" target="_blank">Newsletter</a>
                    </div>
                </div>
            </grid-row>
        </footer>

        <footer id="mandatory-info" class="text-left">
            <grid-row>
                <div class="row justify-content-between">
                    <div class="col">
                        Copyright 2021 Datlab Institut z.s. | IČO 22884165 | Account 2100153900/2100 (Fio Banka a.s.), IBAN
                        CZ8920100000002301898503
                    </div>
                    <div class="col col-auto">{{ $t('email') }}</div>
                </div>
            </grid-row>
        </footer>
    </div>
</template>

<script>
import BlogPosts from "@/components/BlogPosts.vue"

export default {
    props: {
        blankMainSection: { type: Boolean, default: false },
        blogFooter: { type: Boolean, default: true }
    },
    components: {
        BlogPosts
    },
    created: function() {
        this.$store.dispatch("loadLatestDatlabPosts")
    },
    computed: {
        news: function() {
            return this.$store.state.latestDatlabPost
        },
        nav: function () {
            return {
                home: { to: { name: 'home' } },
                parties: { to: { name: 'parties' }, active: this.$route.path.startsWith('/party') },
                donors: { to: { name: 'donors' }, active: this.$route.path.startsWith('/donor') },
                about: { to: { name: 'about' } }
            }
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';

#latest-blog-post {
    border-top: 3px double lighten($dark, 60%);
    padding-top: 48px;
    padding-bottom: 48px;
}
</style>
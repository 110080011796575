<template>
    <div class="detail-tab">
        <DataTable
            class="donos-by-year"
            :data="donos"
            :keys="donosTableKeys"
            :is-busy="busy"
            :per-page="selectedPageSize"
            :total-rows="totalRows"
            :page="currentPage"
            @page-change="(page) => (currentPage = page)"
            @row-clicked="(n) => $router.push({ name: 'party-detail', params: { slug: n.party.slug } })"
            @reset-filter="resetFilter"
        >
            <template #action-bar-left>
                <FilterMultiselect
                    class="party"
                    v-model="selectedParties"
                    :loader="getPartyOptions"
                    :label="$t('party.label')"
                    :placeholder="$t('filter.allAlt')"
                >
                    <template #option="{ option }">
                        <div class="text-truncate">{{ option.name }}</div>
                    </template>

                    <template #tag="{ option }">
                        {{ option.name }}
                    </template>

                    <template #singleValue="{ value }">
                        <span class="value">{{ value.name }}</span>
                    </template>

                    <template #multipleValue="{ values }">
                        {{ $tc('party.pagination.selected', values.length) }}
                    </template>
                </FilterMultiselect>

                <FilterDropdown class="year" :bordered="true" :label="$t('donor.year')" :options="yearOptions" v-model="selectedYear">
                </FilterDropdown>

                <FilterDropdown
                    class="type"
                    :bordered="true"
                    :label="$t('donation.type.label')"
                    :options="typeOptions"
                    v-model="selectedType"
                >
                </FilterDropdown>
            </template>

            <template #action-bar-right>
                <FilterDropdown class="sorting" :label="$t('sorting.label')" :options="sortingOptions" v-model="selectedSortOption" right>
                    <template #selected="data"> {{ data.label }} <icon icon="arrow_down" /> </template>
                </FilterDropdown>
            </template>

            <template #cell(name)="{ data: n }">
                <div class="col col-5 col-md-4 header_row">
                    <div class="row align-items-center">
                        <div class="col col-icon d-none d-sm-block">
                            <div class="logo">
                                <icon icon="party" />
                            </div>
                        </div>
                        <div class="col">
                            <PartyName :data="n.party" />
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(donation_type)="{ item: n, data: d }">
                <div class="col d-none d-md-block col-md-2 text-left">
                    <div class="label">{{ $t('donor.donations.type') }}:</div>
                    <div class="value">{{ formatDonationType(n) }}</div>
                    <div class="value">{{ d.year }}</div>
                </div>
            </template>
            <template #cell(description)="{ item: n }">
                <div class="col d-none d-md-block col-md-2 text-left">
                    <template v-if="n">
                        <div class="label">{{ $t('donor.donations.desc') }}:</div>
                        <div class="value">{{ n }}</div>
                    </template>
                </div>
            </template>
            <template #cell(amount)="{ item: n, data: d }">
                <div class="col col-7 col-md-4 total">
                    <div class="total"><icon icon="gift_in" /> {{ n | price(0) }}</div>
                    <div class="d-block d-md-none sub_block">
                        <div class="value">{{ formatDonationType(d.donation_type) }}</div>
                        <div class="value">{{ d.year }}</div>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FilterDropdown from '@/components/FilterDropdown.vue'
import FilterMultiselect from '@/components/FilterMultiselect.vue'
import PartyName from '@/components/PartyName.vue'
import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        DataTable,
        FilterDropdown,
        FilterMultiselect,
        PartyName
    },
    data: function () {
        return {
            selectedPageSize: 10,
            selectedParty: null,
            selectedYear: null,
            selectedType: null,
            selectedSortOption: null,
            selectedParties: [],
            currentPage: 1,
            totalRows: 0,
            busy: false,
            donos: null
        }
    },
    computed: {
        sortingOptions: function () {
            return [
                { label: this.$t('donation.sorting.options.dono'), value: '-amount' },
                { label: this.$t('donation.sorting.options.year'), value: '-year' }
            ]
        },
        filters: function () {
            var map = {
                year: this.selectedYear,
                party: this.selectedParty,
                type: this.selectedType,
                parties: this.selectedParties.length ? this.selectedParties.map((n) => n.id).join(',') : null
            }

            var filters = {}
            for (const f in map) {
                var val = this.$getSelectedOptionValue(map[f])
                if (val !== null) {
                    filters[f] = val
                }
            }

            return filters
        },
        params: function () {
            return {
                ...{
                    limit: this.selectedPageSize,
                    offset: (this.currentPage - 1) * this.selectedPageSize,
                    order_by: this.$getSelectedOptionValue(this.selectedSortOption),
                    donor: this.data.id
                },
                ...this.filters
            }
        },
        yearOptions: function () {
            return [{ label: this.$t('filter.allAlt'), value: null }].concat(this.$store.state.donationYears)
        },
        donosTableKeys: function () {
            return [{ id: 'name' }, { id: 'donation_type' }, { id: 'description' }, { id: 'amount' }]
        },
        partyOptions: function () {
            return [{ label: this.$t('filter.allAlt'), value: null }].concat(
                this.$store.state.partyNames.map((n) => {
                    return { label: n.name, value: n.id }
                })
            )
        },
        typeOptions: function () {
            return [
                { label: this.$t('filter.all'), value: null },
                { label: this.$t('donation.type.options.donation'), value: 'DONATION' },
                { label: this.$t('donation.type.options.bup'), value: 'BUP' },
                { label: this.$t('donation.type.options.overlimit'), value: 'OVER_LIMIT' }
            ]
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.currentPage = 1
            }
        },
        params: {
            deep: true,
            handler: function () {
                this.loadDonos(this.params)
            }
        }
    },
    created: function () {
        this.$store.dispatch('loadDonationYears', { params: { donor: this.data.id } }).then(() => this.resetFilter())
    },
    methods: {
        resetFilter: function () {
            this.selectedSortOption = this.sortingOptions[0]
            this.selectedYear = this.yearOptions[0]
            this.selectedType = this.typeOptions[0]
            this.selectedParty = this.partyOptions[0]
        },
        loadDonos: function (params) {
            return new Promise((resolve, reject) => {
                this.busy = true

                this.$store.getters.api
                    .get(`${CONFIG.api.endpoints.donation}`, { params: params })
                    .then((response) => {
                        this.totalRows = response.data.meta.total_count
                        this.donos = response.data.objects
                        resolve()
                    })
                    .catch((e) => {
                        this.donos = null
                        console.log(e)
                        reject(e)
                    })
                    .finally(() => (this.busy = false))
            })
        },
        formatDonationType: function (type) {
            switch (type) {
                case 'DONATION':
                    return this.$t('donation.type.options.donation')
                case 'BUP':
                    return this.$t('donation.type.options.bup')
                case 'OVER_LIMIT':
                    return this.$t('donation.type.options.overlimit')
                default:
                    return ''
            }
        },
        getPartyOptions: function (search) {
            return new Promise((resolve, reject) => {
                var params = {
                    q: search,
                    donor: this.data.id
                }

                this.$store.getters.api
                    .get('party_options', { params: params })
                    .then((response) => resolve(response.data))
                    .catch((e) => reject(e))
            })
        }
    }
}
</script>

<style scoped lang="scss">
.board .row:not(:last-child) {
    margin-bottom: 30px;
}
</style>
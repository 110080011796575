<template>
    <router-view />
</template>

<script>
export default {
    created: function() {
        this.setSeoTitle(this.$t("seo.title"))
        this.setSeoDescription(this.$t("seo.description"))

        this.$root.$on("set-page-title", this.setSeoTitle)
        this.$root.$on("set-page-description", this.setSeoDescription)
    },
    watch: {
        "$route": function() {
            this.setSeoTitle(this.$t("seo.title"))
            this.setSeoDescription(this.$t("seo.description"))
        }
    },
    methods: {
        setSeoTitle: function(title) {
            document.title = title
        },
        setSeoDescription: function(description) {
            document.querySelector('meta[name="description"]').setAttribute("content", description);
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/main";
</style>

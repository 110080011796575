import Vue from "vue";
import VueI18n from "vue-i18n";

import cs from "@/messages/cs.json";

Vue.use(VueI18n);

export default new VueI18n({
    locale: "cs", // set locale
    messages: {
        cs: cs
    },
    pluralizationRules: {
        cs: function(choice, choicesLength) {
            if (choice === 0) {
                return 0;
            } else if (choice === 1) {
                return 1;
            } else if (choice >= 2 && choice <= 4) {
                return 2;
            }

            return choicesLength < 4 ? 2 : 3;
        }
    }
});

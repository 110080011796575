<template>
    <layout class="donors">
        <h1>{{ $t('donor.title') }}</h1>

        <DataTable
            :per-page="selectedPageSize"
            :total-rows="totalRows"
            :page="currentPage"
            :data="donors"
            :keys="donorsTableKeys"
            :is-busy="isDonorsLoading"
            @page-change="(page) => (currentPage = page)"
            @row-clicked="(n) => $router.push({ name: 'donor-detail', params: { slug: n.slug } })"
            @reset-filter="resetFilter"
        >
            <template #action-bar-left>
                <FilterMultiselect
                    class="donor"
                    v-model="selectedDonors"
                    :loader="getDonorOptions"
                    :label="$t('donor.label')"
                    :placeholder="$t('filter.allAlt2')"
                >
                    <template #option="{ option }">
                        <DonorName :data="option" simple class="text-truncate" />
                    </template>

                    <template #tag="{ option }">
                        <DonorName :data="option" simple element="span" />
                    </template>

                    <template #singleValue="{ value }">
                        <DonorName :data="value" simple element="span" class="value" />
                    </template>

                    <template #multipleValue="{ values }">
                        {{ $tc('donor.pagination.selected', values.length) }}
                    </template>
                </FilterMultiselect>

                <FilterDropdown class="year" :bordered="true" :label="$t('donor.year')" :options="yearOptions" v-model="selectedYear">
                </FilterDropdown>

                <FilterDropdown
                    class="type"
                    :bordered="true"
                    :label="$t('donation.type.label')"
                    :options="typeOptions"
                    v-model="selectedType"
                >
                </FilterDropdown>

                <FilterDropdown
                    class="type"
                    :bordered="true"
                    :label="$t('donor.risks.filterLabel')"
                    :options="riskTypeOptions"
                    v-model="selectedRiskType"
                >
                </FilterDropdown>
            </template>

            <template #action-bar-right>
                <FilterDropdown class="sorting" :label="$t('sorting.label')" :options="sortingOptions" v-model="selectedSortOption" right>
                    <template #selected="data"> {{ data.label }} <icon icon="arrow_down" /> </template>
                </FilterDropdown>
            </template>

            <template #cell(name)="{ data: n }">
                <div class="col col-5 col-md-4 header_row">
                    <div class="row align-items-center">
                        <div class="col col-icon d-none d-sm-block">
                            <div class="logo">
                                <icon :icon="n.person == 'PHYSICAL' ? 'person' : 'company'" />
                            </div>
                        </div>
                        <div class="col">
                            <DonorName :data="n" />
                            <div class="d-md-none">
                                <template v-if="isDonorRisky(n)">
                                    <span class="label">{{ $t('donor.risks.label') }}:</span>
                                    <span class="value risks"> {{ getDonorRisks(n) | list }}</span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(parties)="{ item: n }">
                <div class="col d-none d-md-block col-md-2">
                    <div class="label">{{ $t('donor.parties') }}:</div>
                    <div class="value">{{ formatParties(n) }}</div>
                </div>
            </template>
            <template #cell(risks)="{ data: n }">
                <div class="col d-none d-md-block col-md-2">
                    <template v-if="isDonorRisky(n)">
                        <div class="label">{{ $t('donor.risks.label') }}:</div>
                        <div class="value risks">{{ getDonorRisks(n) | list }}</div>
                    </template>
                </div>
            </template>
            <template #cell(donations)="{ item: n, data: d }">
                <div class="col col-7 col-md-4 total">
                    <icon icon="gift_out" />{{ n | price(0) }}
                    <div class="d-block d-md-none sub_block">
                        <div class="label">{{ $t('donor.parties') }}:</div>
                        <div class="value">{{ formatParties(d.parties) }}</div>
                    </div>
                </div>
            </template>
        </DataTable>
    </layout>
</template>

<script>
import FilterDropdown from '@/components/FilterDropdown.vue'
import FilterMultiselect from '@/components/FilterMultiselect.vue'
import DataTable from '@/components/DataTable.vue'
import DonorName from '@/components/DonorName.vue'
import donorMixin from '@/plugins/donorMixin.js'

import { CONFIG } from '@/config.js'

export default {
    name: 'Donors',
    components: {
        FilterDropdown,
        FilterMultiselect,
        DataTable,
        DonorName
    },
    mixins: [donorMixin],
    data: function () {
        return {
            isDonorsLoading: false,
            selectedSortOption: null,
            selectedPageSize: 10,
            selectedYear: null,
            selectedParty: null,
            selectedType: null,
            selectedRiskType: null,
            currentPage: 1,
            totalRows: 0,
            chartData: [],
            chartWidth: 0,
            selectedDonors: []
        }
    },
    created: function () {
        window.addEventListener('resize', this.setChartWidth)

        this.loadChartData()

        this.$store.dispatch('loadDonationYears').then(() => this.resetFilter())
    },
    destroyed: function () {
        window.removeEventListener('resize', this.setChartWidth)
    },
    computed: {
        colors: () => ['#dbbdc3', '#e4002c'],
        gmapkey: () => CONFIG.gtmkey,
        donors: function () {
            return this.$store.state.donors
        },
        sortingOptions: function () {
            return [
                { label: this.$t('donor.sorting.options.dono'), value: '-donations' },
                { label: this.$t('donor.sorting.options.alphabet'), value: 'name' }
                // {label: this.$t("donor.sorting.options.risk"), value: "risk"}
            ]
        },
        pageSizeOptions: function () {
            return [10, 20]
        },
        filters: function () {
            var map = {
                year: this.selectedYear,
                party: this.selectedParty,
                type: this.selectedType,
                risk_type: this.selectedRiskType,
                donors: this.selectedDonors.length ? this.selectedDonors.map((n) => n.id).join(',') : null
            }

            var filters = {}
            for (const f in map) {
                var val = this.$getSelectedOptionValue(map[f])
                if (val !== null) {
                    filters[f] = val
                }
            }

            return filters
        },
        params: function () {
            return {
                ...{
                    limit: this.selectedPageSize,
                    offset: (this.currentPage - 1) * this.selectedPageSize,
                    order_by: this.$getSelectedOptionValue(this.selectedSortOption),
                    no_donations: false
                },
                ...this.filters
            }
        },
        yearOptions: function () {
            return [
                    {label: this.$t('filter.allAlt'), value: null},
                    {label: this.$t('filter.last3Years'), value: "last3y"},
                    {label: this.$t('filter.last5Years'), value: "last5y"}
                ]
                .concat(this.$store.state.donationYears)
        },
        donorsTableKeys: function () {
            return [{ id: 'name' }, { id: 'parties' }, { id: 'risks' }, { id: 'donations' }]
        },
        typeOptions: function () {
            return [
                { label: this.$t('filter.all'), value: null },
                { label: this.$t('donation.type.options.donation'), value: 'DONATION' },
                { label: this.$t('donation.type.options.bup'), value: 'BUP' },
                { label: this.$t('donation.type.options.overlimit'), value: 'OVER_LIMIT' }
            ]
        },
        chartOptions: function () {
            return {
                region: 'CZ',
                displayMode: 'markers',
                resolution: 'provinces',
                colorAxis: { colors: this.colors },
                magnifyingGlass: { enable: false },
                width: this.chartWidth,
                height: this.chartHeight,
                keepAspectRatio: false,
                legend: 'none',
                enableRegionInteractivity: false
            }
        },
        chart: function () {
            var header = [
                { type: 'number', label: 'lat' },
                { type: 'number', label: 'lon' },
                { type: 'string' },
                { type: 'number', label: 'amount' },
                { type: 'string', role: 'tooltip' }
            ]

            return [header].concat(
                this.chartData.map((n) => [n.lat, n.lon, n.cities.join(', '), n.amount, this.$options.filters.price(n.amount)])
            )
        },
        chartHeight: function () {
            return this.chartWidth !== null ? this.chartWidth * 0.6 : null
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.currentPage = 1
            }
        },
        params: {
            deep: true,
            handler: function () {
                this.loadDonors(this.params)
            }
        }
    },
    methods: {
        resetFilter: function () {
            this.selectedSortOption = this.sortingOptions[0]
            this.selectedYear = this.yearOptions[0]
            this.selectedType = this.typeOptions[0]
            this.selectedRiskType = this.riskTypeOptions[0]
        },
        loadDonors: function (params) {
            this.isDonorsLoading = true

            this.$store
                .dispatch('loadDonors', params)
                .then((r) => (this.totalRows = r.data.meta.total_count))
                .finally(() => (this.isDonorsLoading = false))
        },
        formatParties: function (parties) {
            if (!parties) {
                return ''
            }

            if (parties.length <= 2) {
                return parties.join(', ')
            } else {
                return `${parties[0]} + ${this.$tc('donor.manyParties', parties.length - 1)}`
            }
        },
        loadChartData: function () {
            this.$store.getters.api
                .get('chart/donors')
                .then((response) => {
                    this.chartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.chartData = []
                })
        },
        setChartWidth: function () {
            var rect = document.querySelector('#map .bubble-content')
            return rect ? rect.getBoundingClientRect().width : 0
        },
        getDonorOptions: function (search) {
            return new Promise((resolve, reject) => {
                var params = {
                    q: search
                }

                this.$store.getters.api
                    .get('donor_options', { params: params })
                    .then((response) => resolve(response.data))
                    .catch((e) => reject(e))
            })
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import '~bootstrap/scss/mixins';

.donors {
    .board {
        margin-bottom: 80px;
    }

    .action-bar {
        margin-bottom: 24px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 5px;
        }

        .sorting {
            .dropdown-toggle {
                img {
                    height: 12px;
                }
            }
        }
    }
}
</style>
<template>
    <div class="detail-tab">
        <div class="board">
            <b-row>
                <b-col class="mb-3 col col-6 col-lg-3">
                    <ValueBubble icon="count" :title="$t('donor.overview.donationsCount')">
                        #{{ data.donations_count | number(0) }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-6 col-lg-3">
                    <ValueBubble icon="count" :title="$t('donor.overview.donationsParties')"> #{{ data.parties_count }} </ValueBubble>
                </b-col>
                <b-col class="mb-3 col col-12 col-lg-6">
                    <ValueBubble icon="sum" :title="$t('donor.overview.donationsTotal')">
                        <icon icon="gift_out" width="16" class="align-top" />{{ data.donations | price(0) }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-12">
                    <ChartBubble :title="$t('charts.donations.label')">
                        <GChart
                            v-if="donationsChart"
                            class="h-100"
                            type="ColumnChart"
                            :data="donationsChart"
                            :options="donationsChartOptions"
                        />
                    </ChartBubble>
                </b-col>

                <b-col class="mb-3 col col-12">
                    <ChartBubble :title="$t('charts.donationsByParty.label')" icon="bubble_chart" class="bubble-chart">
                        <b-row v-for="(n, i) in donationsByPartyChartData" :key="`bubble-chart-${i}`" class="align-items-center">
                            <b-col cols="8">
                                <GChart
                                    type="BubbleChart"
                                    :data="getDonationsByPartyChart(n.data)"
                                    :options="getDonationsByPartyChartOptions(i == donationsByPartyChartData.length - 1)"
                                />
                            </b-col>
                            <b-col cols="4" class="text-truncate label">
                                {{ n.shortcut || n.name }}
                            </b-col>
                        </b-row>
                    </ChartBubble>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ValueBubble from '@/components/ValueBubble.vue'
import ChartBubble from '@/components/ChartBubble.vue'
import chartMixin from '@/plugins/chartMixin.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        ValueBubble,
        ChartBubble
    },
    mixins: [chartMixin],
    data: function () {
        return {
            donationsChartData: [],
            donationsByPartyChartData: []
        }
    },
    computed: {
        color: () => '#e4002c',
        donationsMax: function () {
            return this.donationsChartData.reduce((max, n) => Math.max(max, n[1]), 0)
        },
        donationsAvg: function () {
            if (!this.donationsChartData.length) {
                return 0
            }

            var sum = this.donationsChartData.reduce((sum, n) => sum + n[1], 0)
            return sum / this.donationsChartData.length
        },
        donationsChart: function () {
            if (!this.donationsChartData.length) {
                return null
            }

            var chart = [['year', 'amount', 'avg', { role: 'certainty' }]]

            chart = chart.concat(this.donationsChartData.map((n) => [n[0].toString().substring(2), n[1], this.donationsAvg, false]))

            return chart
        },
        donationsChartOptions: function () {
            var vAxis = {
                ...this.defaultChartOptions.vAxis,
                ...{
                    ticks: [
                        { v: 0, f: this.humanizeNumber(0) },
                        { v: this.donationsAvg, f: `avg ${this.humanizeNumber(this.donationsAvg)}` },
                        { v: this.donationsMax, f: this.humanizeNumber(this.donationsMax) }
                    ]
                }
            }

            var hAxis = this.defaultChartOptions.hAxis
            if (this.donationsChartData.length) {
                var years = this.donationsChartData.map((_, y) => y)
                hAxis = { ...hAxis, ...{ ticks: this.getYearAxisTicks(years) } }
            }

            return {
                ...this.defaultChartOptions,
                ...{
                    colors: [this.color],
                    chartArea: {
                        height: '100%',
                        width: '100%',
                        top: 20,
                        left: 0,
                        right: 100,
                        bottom: 30
                    },
                    vAxis: vAxis,
                    hAxis: hAxis,
                    series: {
                        0: { targetAxisIndex: 1 },
                        1: {
                            targetAxisIndex: 1,
                            type: 'line'
                        }
                    }
                }
            }
        },
        donationsByPartyMax: function () {
            return this.donationsByPartyChartData.reduce((max, n) => Math.max(max, ...Object.values(n.data)), 0)
        },
        chartBubbleSize: () => 30
    },
    created: function () {
        this.loadDonationsChartData()
        this.loadDonationsByPartyChartData()
    },
    methods: {
        loadDonationsChartData: function () {
            this.$store.getters.api
                .get('chart/donations', { params: { donor: this.data.id } })
                .then((response) => {
                    this.donationsChartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.donationsChartData = []
                })
        },
        humanizeNumber: function (number) {
            return this.$options.filters.humanize_price_for_chart(number)
        },
        loadDonationsByPartyChartData: function () {
            this.$store.getters.api
                .get('chart/party_donations', { params: { donor: this.data.id } })
                .then((response) => {
                    this.donationsByPartyChartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.donationsByPartyChartData = []
                })
        },
        getDonationsByPartyChart: function (data) {
            var chart = [['id', 'x', 'y', 'color', 'amount']]

            chart = chart.concat(Object.entries(data).map(([y, n]) => ['', parseInt(y), this.chartBubbleSize, 1, n]))

            return chart
        },
        getDonationsByPartyChartOptions: function (last = false) {
            var vticks = Array.from(new Array(3), (_, i) => i * this.chartBubbleSize)

            var hticks = []
            if (this.donationsByPartyChartData.length) {
                var max_year = this.donationsByPartyChartData.reduce((max, n) => Math.max(max, ...Object.keys(n.data)), 0)
                var min_year = this.donationsByPartyChartData.reduce((min, n) => Math.min(min, ...Object.keys(n.data)), max_year)

                hticks = this.getYearAxisTicks([min_year, max_year])
                hticks.splice(0, 0, { v: min_year - 1, f: '' })
                hticks.push({ v: max_year + 1, f: '' })
            }

            var bottomOffset = last ? 30 : 0

            return {
                bubble: { stroke: 'transparent', textStyle: { color: 'transparent' } },
                legend: { position: 'none' },
                backgroundColor: 'transparent',
                colors: [this.color],
                fontName: 'Inter',
                fontSize: 14,
                enableInteractivity: false,
                sizeAxis: {
                    minValue: 0,
                    maxValue: this.donationsByPartyMax,
                    minSize: 5,
                    maxSize: this.chartBubbleSize
                },
                chartArea: {
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: bottomOffset
                },
                height: this.chartBubbleSize * 2 + 20 + bottomOffset,
                vAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                    textStyle: {
                        color: 'gray'
                    },
                    viewWindowMode: 'maximized',
                    ticks: vticks
                },
                hAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                    textStyle: {
                        color: 'gray'
                    },
                    slantedText: false,
                    ticks: hticks
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import 'src/scss/custom';

.board .row:not(:last-child) {
    margin-bottom: 30px;
}

.bubble-chart .label {
    color: $light;
    font-size: 14px;
}

.bubble-chart .bubble-content > .row:last-of-type .label {
    padding-bottom: 30px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multiselect',_vm._b({staticClass:"filter-multiselect",on:{"search-change":_vm.getOptions,"input":function (e) { return _vm.$emit('input', e); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._t("option",null,{"option":option})]}},{key:"noResult",fn:function(){return [(_vm.busy)?[_vm._v(" "+_vm._s(_vm.$t('home.search.loader'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('home.search.noData'))+" ")]]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('home.search.noOptions'))+" ")]},proxy:true},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"multiselect__tag"},[_vm._t("tag",null,{"option":option}),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"mousedown":function($event){$event.preventDefault();return remove(option)}}})],2)]}},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(!isOpen && values.length)?[_c('span',{staticClass:"multiselect__single"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+": ")]),(values.length == 1)?[_vm._t("singleValue",null,{"value":values[0]})]:_c('span',{staticClass:"value"},[_vm._t("multipleValue",null,{"values":values})],2)],2)]:_vm._e()]}},{key:"placeholder",fn:function(){return [(_vm.label)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+": ")]):_vm._e(),(_vm.placeholder)?_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'multiselect',_vm.searchSettings,false))}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <layout-detail class="donor-detail"
        :tabs="tabs"
        :busy="isBusy"
    >
        <template #title>
            <DonorName element="h1" :data="data">
                <template #extras="{data}">
                    <template v-if="data.ico">
                        <span class="label">{{ $t("home.donor.vatId") | upper }}: </span>
                        <span class="value">{{ data.ico }}</span>
                    </template>
                    <template v-if="data.city">
                        <span class="label">{{ $t("donor.city") }}: </span>
                        <span class="value">{{ data.city }}</span>
                    </template>
                </template>
            </DonorName>
        </template>
        <template #subtitle>
            {{ $t("donor.subtitle") }}
        </template>

        <template #before>
            <b-alert class="duplicates-alert" v-if="showDuplicatesAlert" show variant="warning" dismissible>
                <div class="title">{{ $t('donor.alert', {name: $options.filters.donor_name(data)}) }}</div>

                <span v-for="(d, i) in data.duplicates" :key="d.slug">
                    {{ i + 1 }}.&nbsp;
                    <router-link :to="{name: 'donor-detail', params: {slug: d.slug}}">{{ d.parties }}</router-link>
                    <template v-if="d.city">&nbsp;({{d.city}})</template>
                    <template v-if="i < data.duplicates.length - 1">,&nbsp;</template>
                </span>
            </b-alert>
        </template>

        <template #tab(0)>
            <TabOverview :data="data" />
        </template>

        <template #tab(1)>
            <TabDonations :data="data" />
        </template>

        <template #tab(2)>
            <TabTenders :data="data" />
        </template>

        <template #tab(3)>
            <TabEUSubs :data="data" />
        </template>
    </layout-detail>
</template>

<script>
import TabOverview from '@/components/detail/donor/TabOverview.vue'
import TabDonations from '@/components/detail/donor/TabDonations.vue'
import TabTenders from '@/components/detail/donor/TabTenders.vue'
import TabEUSubs from '@/components/detail/donor/TabEUSubs.vue'
import DonorName from "@/components/DonorName.vue"

import {CONFIG} from '@/config.js'

export default {
    name: "DonorDetail",
    props: {
        slug:  {type: [String, Number], required: true}
    },
    components: {
        TabOverview,
        DonorName,
        TabDonations,
        TabTenders,
        TabEUSubs
    },
    data: function() {
        return {
            isBusy: false,
            data: null,
            enabledTabs: {}
        }
    },
    computed: {
        tabs: function() {
            var tabs = [
                {label: this.$t("donor.tabs.overview")},
                {label: this.$t("donor.tabs.donations"), disabled: !this.enabledTabs.donos}
            ]

            if (this.data && this.data.person == 'JURIDICAL') {
                tabs.push(
                    // this.$t("donor.tabs.risks"),
                    {label: this.$t("donor.tabs.tenders"), disabled: !this.enabledTabs.tenders},
                    // this.$t("donor.tabs.contracts"),
                    {label: this.$t("donor.tabs.euFound"), disabled: !this.enabledTabs.subs}
                )
            }

            return tabs
        },
        showDuplicatesAlert: function() {
            return this.data && this.data.duplicates && this.data.duplicates.length
        }
    },
    watch: {
        "$route": function() {
            this.init()
        }
    },
    created: function() {
        this.init()
    },
    methods: {
        init: function() {
            this.isBusy = true

            this.loadDetail()
                .then(() => {
                    this.$root.$emit("set-page-title", this.$t("donor.seo.title", {donor: this.$options.filters.donor_name(this.data)}))
                    this.$root.$emit("set-page-description", this.$t("donor.seo.description",
                        {
                            amount: this.$options.filters.price(this.data.donations, 0, false),
                            parties: this.$options.filters.list(this.data.parties)
                        }))

                    return this.loadTabs()
                })
                .finally(() => this.isBusy = false)
        },
        loadDetail: function() {
            return this.$store.getters.api
                .get(`${CONFIG.api.endpoints.donor}${this.slug}/`, {params: {advanced: true}})
                .then(response => {
                    this.data = response.data
                })
                .catch(e => {
                    this.data = null
                    console.log(e)
                })
        },
        loadTabs: function() {
            return this.$store.getters.api
                .get("donor_tabs", {params: {donor: this.data.id}})
                .then(response => {
                    this.enabledTabs = response.data
                })
                .catch(e => {
                    this.enabledTabs = {}
                    console.log(e)
                })
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";

.duplicates-alert {
    padding: 24px;
    margin-top: -60px;
    margin-bottom: 80px;

    .title {
        margin-bottom: 20px;
    }

    span {
        font-size: 14px;
        white-space: nowrap;
        display: inline-block;
        line-height: 2;
        margin-right: 1ex;

        a {
            color: inherit;
            font-weight: 700;
        }
    }
}
</style>
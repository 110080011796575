<template>
    <ContentBubble :icon="icon" :title="title" class="value-bubble">
        <slot></slot>
    </ContentBubble>
</template>

<script>
import ContentBubble from '@/components/ContentBubble.vue'

export default {
    props: {
        icon: { type: String },
        title: { type: String }
    },
    components: {
        ContentBubble
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import '~bootstrap/scss/mixins';

.value-bubble .bubble-content {
    justify-content: center;
    padding: 0 10px;
    text-align: center;
    font-family: $font-family-monospace;
    font-size: 40px;
    font-weight: 500;
    line-height: 1;

    @include media-breakpoint-down(sm) {
        font-size: 30px;
        font-weight: 700;
        padding: 0px;
    }
}
</style>
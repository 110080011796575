<template>
    <multiselect
        v-model="value"
        v-bind="searchSettings"
        @search-change="getOptions"
        @input="(e) => $emit('input', e)"
        class="filter-multiselect"
    >
        <template #option="{ option }">
            <slot name="option" :option="option"></slot>
        </template>

        <template #noResult>
            <template v-if="busy">
                {{ $t('home.search.loader') }}
            </template>
            <template v-else>
                {{ $t('home.search.noData') }}
            </template>
        </template>

        <template #noOptions>
            {{ $t('home.search.noOptions') }}
        </template>

        <template #tag="{ option, remove }">
            <span class="multiselect__tag">
                <slot name="tag" :option="option"></slot>
                <i aria-hidden="true" tabindex="1" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
            </span>
        </template>

        <template #selection="{ values, isOpen }">
            <template v-if="!isOpen && values.length">
                <span class="multiselect__single">
                    <span class="label">{{ label }}: </span>
                    <template v-if="values.length == 1">
                        <slot name="singleValue" :value="values[0]"></slot>
                    </template>
                    <span v-else class="value">
                        <slot name="multipleValue" :values="values"></slot>
                    </span>
                </span>
            </template>
        </template>

        <template #placeholder>
            <span v-if="label" class="label">{{ label }}: </span>
            <span v-if="placeholder" class="value">{{ placeholder }}</span>
        </template>
    </multiselect>
</template>

<script>
export default {
    model: {
        prop: 'selected',
        event: 'selected-changed'
    },
    props: {
        selected: { default: null },
        loader: { type: Function },
        label: { type: String },
        placeholder: { type: String }
    },
    data: function () {
        return {
            busy: false,
            searchTimeout: null,
            value: null,
            options: []
        }
    },
    computed: {
        searchSettings: function () {
            return {
                searchable: true,
                clearOnSelect: false,
                closeOnSelect: false,
                showLabels: false,
                internalSearch: false,
                preserveSearch: true,
                resetAfter: false,
                placeholder: null,
                options: this.options,
                openDirection: 'bottom',
                multiple: true,
                trackBy: 'id'
            }
        },
        searchDebounce: () => 700
    },
    watch: {
        value: function () {
            this.$emit('selected-changed', this.value)
        }
    },
    methods: {
        getOptions: function (value) {
            this.$emit('search-change', value)

            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout)
            }

            this.options = []

            if (!value) {
                return
            }

            this.busy = true

            this.searchTimeout = setTimeout(() => {
                this.loader(value)
                    .then((options) => (this.options = options))
                    .catch((e) => {
                        this.options = []
                        console.log(e)
                    })
                    .finally(() => (this.busy = false))
            }, this.searchDebounce)
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';
@import '~bootstrap/scss/mixins';

$multiselect-max-width: 550px;

.filter-multiselect {
    color: $body-color;
    display: inline-block;
    margin-right: 15px;
    width: auto;

    @include media-breakpoint-down(sm) {
        font-size: 12px;
        width: 325px;
    }

    .multiselect__content-wrapper {
        border: 3px solid $dark;
        border-radius: 15px;
        width: auto;
        max-width: $multiselect-max-width;
        top: calc(100% + 3px);

        .multiselect__content {
            width: 100%;
        }
    }

    .multiselect__option--group {
        padding: 18px 14px 18px 14px;
        font-size: 18px;
        background-color: transparent !important;
        color: $body-color !important;
        font-weight: 500;
    }

    .multiselect__element .multiselect__option--group {
        border-bottom: 3px double $light;
        border-top: 3px double $light;
    }

    .multiselect__element:first-of-type .multiselect__option--group {
        border-top: none;
    }

    .multiselect__option:not(.multiselect__option--group) {
        padding: 14px 14px 14px 28px;
        line-height: unset;
        font-size: 16px;

        .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
        }

        .ico {
            padding-left: 10px;

            .value {
                font-family: $font-family-monospace;
            }
        }
    }

    .multiselect__tags {
        font-size: 16px;
        margin-bottom: 5px;
        border: 1px solid $light;
        border-radius: 15px;
        padding: 15px 25px;
        margin: 2px;
        line-height: 24px;
        max-width: $multiselect-max-width;

        &:hover {
            border: solid 3px $dark !important;
            margin: 0;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            padding: 2px 10px 2px 10px;
            min-height: 15px;
        }
    }

    // hide caret
    .multiselect__select {
        display: none;
    }

    .multiselect__option--highlight {
        background: $primary;
    }

    .multiselect__tag-icon {
        @include colorize-clickable($primary);

        &::after {
            color: white;
        }
    }

    .multiselect__option--selected {
        background-color: $background-light;
    }
    .multiselect__option--selected.multiselect__option--highlight,
    .multiselect__option--group-selected.multiselect__option--highlight {
        background: $warning;
    }

    .multiselect__option.multiselect__option--highlight * {
        color: white !important;
    }

    .multiselect__content,
    .multiselect__input,
    .multiselect__tags {
        background-color: $body-bg;
    }

    .multiselect__input {
        margin: 0;
        padding-left: 0;
        font-size: 16px;
    }

    .multiselect__single {
        margin: 0;
        vertical-align: middle;
        padding: 0;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    .multiselect__placeholder {
        margin: 0;
        padding: 0;
    }

    &.multiselect--active {
        .multiselect__tags {
            border-radius: 15px;
            border: 3px solid $dark;
            margin: 0;
        }
    }

    span.label {
        font-weight: 500;
        color: $light;
    }

    span.value {
        font-weight: 500;
        color: $body-color;
    }
}
</style>
<template>
    <layout class="home text-center" :blog-footer="false">
        <h1>{{ $t('home.title') }}</h1>

        <div class="search">
            <div class="label"><span v-html="$t('home.search.label')"></span></div>
            <multiselect v-model="search" v-bind="searchSettings" @search-change="getSearchOptions" @select="(o) => goToDetail(o)">
                <template #option="{ option: o }">
                    <template v-if="o.$isLabel">
                        <strong>{{ $t(`home.search.${o.$groupLabel}`) }}</strong>
                    </template>
                    <template v-else>
                        <div class="d-flex justify-content-between">
                            <template v-if="o.person == 'PHYSICAL'">
                                <div class="name">{{ o.title }} {{ o.firstname }} {{ o.lastname }} {{ o.title_after }}</div>
                            </template>
                            <template v-else>
                                <div class="name">{{ o.name }}</div>
                                <div v-if="o.ico" class="ico">
                                    <span class="label">{{ $t('home.donor.vatId') | upper }}: </span>
                                    <span class="value">{{ o.ico | ico }}</span>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>

                <template #noResult>
                    <template v-if="isSearchOptionsLoading">
                        {{ $t('home.search.loader') }}
                    </template>
                    <template v-else>
                        {{ $t('home.search.noData') }}
                    </template>
                </template>

                <template #noOptions>
                    {{ $t('home.search.noOptions') }}
                </template>
            </multiselect>
        </div>

        <BlogPosts class="news" />

        <div class="parties">
            <div class="content-title">
                <h2>{{ $t('home.parties') }}</h2>
                <router-link :to="{ name: 'parties' }">{{ $t('home.allParties') }} <icon icon="dbl_arrow" /></router-link>
            </div>

            <DataTable
                :data="topParties"
                :keys="partiesTableKeys"
                :is-busy="isTopPartiesLoading"
                @row-clicked="(n) => $router.push({ name: 'party-detail', params: { slug: n.slug } })"
                simple
            >
                <template #cell(name)="{ data: n }">
                    <div class="col col-6 col-md-4 header_row">
                        <div class="row align-items-center">
                            <div class="col col-icon d-none d-sm-block">
                                <div class="logo">
                                    <icon icon="party" />
                                </div>
                            </div>
                            <div class="col">
                                <PartyName :data="n" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(income)="{ item: n }">
                    <div class="col d-none d-md-block col-md-2 text-right text-sm-left">
                        <div class="label">{{ $t('party.income') }}:</div>
                        <div class="value income">+ {{ (n / 1000) | price(0) }}</div>
                    </div>
                </template>
                <template #cell(outcome)="{ item: n }">
                    <div class="col d-none d-md-block col-md-2 text-right text-sm-left">
                        <div class="label">{{ $t('party.outcome') }}:</div>
                        <div class="value outcome">- {{ (n / 1000) | price(0) }}</div>
                    </div>
                </template>
                <template #cell(donations)="{ item: n, data: d }">
                    <div class="col col-6 col-md-4 total">
                        <icon icon="gift_in" />{{ n | price(0) }}
                        <div class="d-block d-md-none sub_block">
                            <div class="label">{{ $t('party.income') }}:</div>
                            <div class="value income">+ {{ (d.income / 1000) | price(0) }}</div>
                            <div class="label">{{ $t('party.outcome') }}:</div>
                            <div class="value outcome">- {{ (d.outcome / 1000) | price(0) }}</div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>

        <div class="donors">
            <div class="content-title">
                <h2>{{ $t('home.donors') }}</h2>
                <router-link :to="{ name: 'donors' }">{{ $t('home.allDonors') }} <icon icon="dbl_arrow" /></router-link>
            </div>

            <DataTable
                :data="topDonors"
                :keys="donorsTableKeys"
                :is-busy="isTopDonorsLoading"
                @row-clicked="(n) => $router.push({ name: 'donor-detail', params: { slug: n.slug } })"
                simple
            >
                <template #cell(name)="{ data: n }">
                    <div class="col col-5 col-md-4 header_row">
                        <div class="row align-items-center">
                            <div class="col col-icon d-none d-sm-block">
                                <div class="logo">
                                    <icon :icon="n.person == 'PHYSICAL' ? 'person' : 'company'" />
                                </div>
                            </div>
                            <div class="col">
                                <DonorName :data="n" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(parties)="{ item: n }">
                    <div class="col d-none d-md-block col-md-2 text-left">
                        <div class="label">{{ $t('donor.parties') }}:</div>
                        <div class="value">{{ formatParties(n) }}</div>
                    </div>
                </template>
                <template #cell(risks)="{ data: n }">
                    <div class="col d-none d-md-block col-md-2 text-right">
                        <template v-if="n.eusubs">
                            <div class="label">{{ $t('donor.risks.label') }}:</div>
                            <div class="value risks">{{ getDonorRisks(n) | list }}</div>
                        </template>
                    </div>
                </template>
                <template #cell(donations)="{ item: n, data: d }">
                    <div class="col col-7 col-md-4 total">
                        <icon icon="gift_out" />{{ n | price(0) }}
                        <div class="d-block d-md-none sub_block">
                            <div class="label">{{ $t('donor.parties') }}:</div>
                            <div class="value">{{ formatParties(d.parties) }}</div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>
    </layout>
</template>

<script>
import DonorName from '@/components/DonorName.vue'
import PartyName from '@/components/PartyName.vue'
import DataTable from '@/components/DataTable.vue'
import BlogPosts from "@/components/BlogPosts.vue"

export default {
    name: 'Home',
    components: {
        DonorName,
        DataTable,
        PartyName,
        BlogPosts
    },
    data: function () {
        return {
            search: null,
            searchOptions: [],
            isSearchOptionsLoading: false,
            searchTimeout: null,
            isTopPartiesLoading: false,
            isTopDonorsLoading: false
        }
    },
    created: function () {
        this.$store.dispatch('loadLatestDatlabPosts')

        this.isTopPartiesLoading = true
        this.$store.dispatch('loadTopParties').finally(() => (this.isTopPartiesLoading = false))

        this.isTopDonorsLoading = true
        this.$store.dispatch('loadTopDonors').finally(() => (this.isTopDonorsLoading = false))
    },
    computed: {
        news: function () {
            return this.$store.state.latestDatlabPost
        },
        topParties: function () {
            return this.$store.state.topParties
        },
        topDonors: function () {
            return this.$store.state.topDonors
        },
        searchSettings: function () {
            return {
                searchable: true,
                clearOnSelect: false,
                showLabels: false,
                internalSearch: false,
                preserveSearch: true,
                resetAfter: true,
                placeholder: null,
                options: this.searchOptions,
                groupLabel: 'group',
                groupValues: 'items',
                openDirection: 'bottom',
                optionHeight: 50
            }
        },
        searchDebounce: () => 700,
        donorsTableKeys: function () {
            return [{ id: 'name', top: true }, { id: 'parties' }, { id: 'risks' }, { id: 'donations' }]
        },
        partiesTableKeys: function () {
            return [{ id: 'name', top: true }, { id: 'income' }, { id: 'outcome' }, { id: 'donations' }]
        }
    },
    methods: {
        formatParties: function (parties) {
            if (!parties) {
                return ''
            }

            if (parties.length <= 2) {
                return parties.join(', ')
            } else {
                return `${parties[0]} + ${this.$tc('home.donor.manyParties', parties.length - 1)}`
            }
        },
        getSearchOptions: function (value) {
            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout)
            }

            this.searchOptions = []

            if (!value) {
                return
            }

            var params = {
                q: value
            }

            this.isSearchOptionsLoading = true

            this.searchTimeout = setTimeout(() => {
                Promise.all([
                    this.$store.getters.api.get('party_options', { params: params }),
                    this.$store.getters.api.get('donor_options', { params: params })
                ])
                    .then((response) => {
                        response[0].data.forEach((n) => (n.group = 'party'))
                        response[1].data.forEach((n) => (n.group = 'donor'))
                        this.searchOptions = [
                            {
                                group: 'party',
                                items: response[0].data
                            },
                            {
                                group: 'donor',
                                items: response[1].data
                            }
                        ]
                    })
                    .catch((e) => {
                        this.searchOptions = []
                        console.log(e)
                    })
                    .finally(() => (this.isSearchOptionsLoading = false))
            }, this.searchDebounce)
        },
        getHealthIndex: function (party) {
            var value = (party.debts || 0) / Math.max(party.avg_income - party.avg_outcome, 1)

            if (0 >= value <= 1) {
                return 'A'
            } else if (1 > value < 2) {
                return 'B'
            } else {
                return 'C'
            }
        },
        getDonorRisks: function (data) {
            var risks = []
            if (data.eusubs) {
                risks.push(this.$t('donor.eusubs.label'))
            }

            return risks
        },
        goToDetail: function (data) {
            this.$router.push({
                name: data.group == 'party' ? 'party-detail' : 'donor-detail',
                params: { slug: data.slug }
            })
        }
    }
}
</script>


<style scoped lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';
@import '~bootstrap/scss/mixins';

.home h1 {
    font-size: 45px;
    margin-bottom: 24px;

    @include media-breakpoint-up(sm) {
        font-size: 60px;
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(md) {
        font-size: 72px;
        margin-bottom: 56px;
    }
}

.content-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;

    & > * {
        color: $body-color;
        margin-bottom: 0;
    }

    a {
        font-weight: 600;
        font-size: 24px;
    }
}

.parties {
    margin-bottom: 64px;

    .table-row {
        .income,
        .outcome {
            font-family: $font-family-monospace;
        }

        .income {
            color: $color-green;
        }

        .outcome {
            color: $color-red;
        }
    }
}

.donors {
    .table-row {
        .ico {
            margin-top: 2px;
        }
    }
}

.alert {
    padding: 20px 20px 20px 54px;
    margin-bottom: 64px;
}

.search {
    margin-bottom: 64px;
    text-align: center;

    & > .label {
        font-size: 12px;
        margin-bottom: 13px;
    }

    .search-input {
        margin: 0 auto;
        max-width: 465px;

        svg {
            height: 24px;
            width: auto;
        }

        .input-group-prepend {
            border: 4px solid $dark;
            border-right: none;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            padding: 14px;
        }

        input {
            border: 4px solid $dark;
            border-left: none;
            height: auto;
            padding-left: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.news {
    margin-bottom: 32px;
}
</style>

<style lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';

.search {
    .multiselect {
        margin: 0 auto;
        max-width: 465px;
        color: $body-color;

        .multiselect__content-wrapper {
            border: 4px solid $dark;
            border-radius: $border-radius;
            width: 100%;

            .multiselect__content {
                width: 100%;
            }
        }

        .multiselect__option--group {
            padding: 14px;
            font-size: 18px;
            background-color: transparent !important;
            color: $body-color !important;
            font-weight: 500;
            height: 50px;
        }

        .multiselect__element .multiselect__option--group {
            border-bottom: 3px double $light;
            border-top: 3px double $light;
        }

        .multiselect__element:first-of-type .multiselect__option--group {
            border-top: none;
        }

        .multiselect__option:not(.multiselect__option--group) {
            padding: 14px 14px 14px 28px;
            line-height: unset;
            font-size: 16px;
            height: 50px;

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 500;
            }

            .ico {
                padding-left: 10px;

                .value {
                    font-family: $font-family-monospace;
                }
            }
        }

        .multiselect__tags {
            font-size: 20px;
            margin-bottom: 5px;
            border: 4px solid $dark;
            border-radius: $border-radius;
            padding: 14px 14px 14px 52px;
            height: 60px;

            background: url('../../public/images/search.svg') 14px 14px no-repeat;
            background-size: 24px 24px;
        }

        // hide caret
        .multiselect__select {
            display: none;
        }

        .multiselect__option--highlight {
            background: $primary;
        }

        .multiselect__tag-icon {
            @include colorize-clickable($primary);

            &::after {
                color: white;
            }
        }

        .multiselect__option--selected {
            background-color: $background-light;
        }
        .multiselect__option--selected.multiselect__option--highlight,
        .multiselect__option--group-selected.multiselect__option--highlight {
            background: $warning;
        }

        .multiselect__option.multiselect__option--highlight * {
            color: white !important;
        }

        .multiselect__content,
        .multiselect__input,
        .multiselect__tags {
            background-color: $body-bg;
        }

        .multiselect__input {
            margin: 0;
            padding-left: 0;
            font-size: 20px;
        }
    }
}
</style>
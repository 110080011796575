<template>
    <div class="content-bubble" :class="variantClass">
        <slot name="title">
            <div v-if="title" class="bubble-title">
                <icon v-if="icon" :icon="icon" /> <small>{{ title }}</small>
            </div>
        </slot>
        <div class="bubble-content">
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: { type: String },
        title: { type: String },
        variant: { type: String }
    },
    computed: {
        variantClass: function () {
            return this.variant ? `alert-${this.variant}` : null
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';
@import '~bootstrap/scss/mixins';

.content-bubble {
    @include make-rounded();

    padding: 24px;
    display: flex;
    flex-flow: column;

    @include media-breakpoint-down(sm) {
        padding: 15px;
    }

    &:not([class*='alert']) {
        background-color: $background-light;
    }

    img {
        margin-right: 4px;
    }

    small {
        font-size: 12px;
    }

    .bubble-content {
        display: flex;
        align-items: center;
        justify-content: left;

        font-size: 14px;
        font-weight: 400;
        line-height: 1.71;
        height: 100%;

        & > div {
            width: 100%;
        }

        a {
            font-weight: 700;
            color: inherit;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .bubble-title {
        margin-bottom: 16px;
    }
}

*[class*='col'] > .content-bubble {
    height: 100%;
}
</style>
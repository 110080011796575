<template>
    <div>
        <div class="name">{{ data.name }}</div>
        <div>
            <span class="label">{{ $t("party.index") }}: </span>
            <span class="value">{{ data.hindex }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {type: Object, required: true}
    }
}
</script>

<style>

</style>
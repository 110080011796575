var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tab"},[_c('div',{staticClass:"board"},[_c('b-row',[_c('b-col',{staticClass:"mb-3 col col-12 col-sm-3"},[_c('ValueBubble',{staticClass:"hindex",attrs:{"icon":"fhealth","title":_vm.$t('party.overview.index'),"data-value":_vm.data.hindex}},[_vm._v(" "+_vm._s(_vm.data.hindex)+" ")])],1),_c('b-col',{staticClass:"mb-3 col col-12 col-sm-9"},[_c('ContentBubble',{attrs:{"icon":"methodology","title":_vm.$t('party.fhealth.methodTitle')}},[_vm._v(" "+_vm._s(_vm.$t('party.fhealth.method'))+" ")])],1),_c('b-col',{staticClass:"col col-12"},[_c('ChartBubble',{attrs:{"title":_vm.$t('charts.income.label'),"legend":[
                        { color: _vm.color.sub, label: _vm.$t('charts.income.sub') },
                        { color: _vm.color.dono, label: _vm.$t('charts.income.dono') },
                        { color: _vm.color.other, label: _vm.$t('charts.income.other') }
                    ]}},[_c('GChart',{attrs:{"type":"ColumnChart","data":_vm.incomeChart,"options":_vm.incomeChartOptions}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('ChartBubble',{attrs:{"title":_vm.$t('charts.cashflow.label'),"legend":[
                        { color: _vm.color.income, label: _vm.$t('charts.cashflow.income') },
                        { color: _vm.color.outcome, label: _vm.$t('charts.cashflow.outcome') },
                        { color: _vm.color.debt, label: _vm.$t('charts.cashflow.debt') }
                    ]}},[_c('GChart',{attrs:{"type":"AreaChart","data":_vm.cashflowChart,"options":_vm.cashflowChartOptions}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
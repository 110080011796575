<template>
    <div class="detail-tab">
        <div class="board">
            <b-row>
                <b-col class="mb-3 col col-12 col-sm-3">
                    <ValueBubble icon="fhealth" :title="$t('party.overview.index')" :data-value="data.hindex" class="hindex">
                        {{ data.hindex }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-12 col-sm-9">
                    <ContentBubble icon="methodology" :title="$t('party.fhealth.methodTitle')">
                        {{ $t('party.fhealth.method') }}
                    </ContentBubble>
                </b-col>

                <b-col class="col col-12">
                    <ChartBubble
                        :title="$t('charts.income.label')"
                        :legend="[
                            { color: color.sub, label: $t('charts.income.sub') },
                            { color: color.dono, label: $t('charts.income.dono') },
                            { color: color.other, label: $t('charts.income.other') }
                        ]"
                    >
                        <GChart type="ColumnChart" :data="incomeChart" :options="incomeChartOptions" />
                    </ChartBubble>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <ChartBubble
                        :title="$t('charts.cashflow.label')"
                        :legend="[
                            { color: color.income, label: $t('charts.cashflow.income') },
                            { color: color.outcome, label: $t('charts.cashflow.outcome') },
                            { color: color.debt, label: $t('charts.cashflow.debt') }
                        ]"
                    >
                        <GChart type="AreaChart" :data="cashflowChart" :options="cashflowChartOptions" />
                    </ChartBubble>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ValueBubble from '@/components/ValueBubble.vue'
import ContentBubble from '@/components/ContentBubble.vue'
import ChartBubble from '@/components/ChartBubble.vue'
import chartMixin from '@/plugins/chartMixin.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        ValueBubble,
        ContentBubble,
        ChartBubble
    },
    mixins: [chartMixin],
    data: function () {
        return {
            incomeChartData: [],
            cashflowChartData: []
        }
    },
    computed: {
        incomeChart: function () {
            var chart = [
                [
                    'year',
                    'sub',
                    { type: 'string', role: 'tooltip' },
                    'other',
                    { type: 'string', role: 'tooltip' },
                    'dono',
                    { type: 'string', role: 'tooltip' }
                ]
            ]

            if (this.incomeChartData.length) {
                chart = chart.concat(
                    this.incomeChartData.map((n) => {
                        return [
                            n.year.toString().substring(2),
                            n.dono,
                            `${this.$t('charts.income.dono')}: ${this.humanizeNumber(n.dono)}`,
                            n.other,
                            `${this.$t('charts.income.other')}: ${this.humanizeNumber(n.other)}`,
                            n.sub,
                            `${this.$t('charts.income.sub')}: ${this.humanizeNumber(n.sub)}`
                        ]
                    })
                )
            }
            return chart
        },
        color: function () {
            return {
                dono: '#ed8298',
                other: '#f0abb9',
                sub: '#e4002c',
                income: '#428712',
                outcome: '#e4002c',
                debt: '#cdcccd'
            }
        },
        incomeChartOptions: function () {
            var vAxis = { ...this.defaultChartOptions.vAxis, ...{ ticks: [0, 1] } }

            return {
                ...this.defaultChartOptions,
                ...{
                    isStacked: 'percent',
                    enableInteractivity: true,
                    colors: [this.color.dono, this.color.other, this.color.sub],
                    series: {
                        0: { targetAxisIndex: 1 },
                        1: { targetAxisIndex: 1 },
                        2: { targetAxisIndex: 1 }
                    },
                    height: 300,
                    vAxis: vAxis
                }
            }
        },
        cashflowChart: function () {
            var stroke_style = 'stroke-width: 1;'
            var style = `fill-color: transparent; ${stroke_style}`
            var chart = [
                ['year', 'debt', { role: 'style' }, { role: 'certainty' }, 'outcome', { role: 'style' }, 'income', { role: 'style' }]
            ]
            if (this.cashflowChartData.length) {
                chart = chart.concat(
                    this.cashflowChartData.map((n) => [
                        n.year.toString().substring(2),
                        n.debt,
                        stroke_style,
                        false,
                        n.outcome,
                        style,
                        n.income,
                        style
                    ])
                )
            }
            return chart
        },
        cashflowMax: function () {
            return this.cashflowChartData.reduce((max, n) => Math.max(max, n.debt, n.income, n.outcome), 0)
        },
        cashflowChartOptions: function () {
            var vAxis = {
                ...this.defaultChartOptions.vAxis,
                ...{
                    ticks: [
                        { v: 0, f: this.humanizeNumber(0) },
                        { v: this.cashflowMax, f: this.humanizeNumber(this.cashflowMax) }
                    ],
                    format: 'long'
                }
            }

            return {
                ...this.defaultChartOptions,
                ...{
                    isStacked: false,
                    colors: [this.color.debt, this.color.outcome, this.color.income],
                    series: {
                        0: { targetAxisIndex: 1 },
                        1: { targetAxisIndex: 1 },
                        2: { targetAxisIndex: 1 }
                    },
                    height: 300,
                    vAxis: vAxis,
                    chartArea: {
                        height: '100%',
                        width: '100%',
                        top: 20,
                        left: 20,
                        right: 100,
                        bottom: 30
                    }
                }
            }
        }
    },
    created: function () {
        this.loadIncomeChartData()
        this.loadCashflowChartData()
    },
    methods: {
        loadIncomeChartData: function () {
            this.$store.getters.api
                .get('chart/income', { params: { party: this.data.id } })
                .then((response) => {
                    this.incomeChartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.incomeChartData = []
                })
        },
        loadCashflowChartData: function () {
            this.$store.getters.api
                .get('chart/cashflow', { params: { party: this.data.id } })
                .then((response) => {
                    this.cashflowChartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.cashflowChartData = []
                })
        },
        humanizeNumber: function (number) {
            return this.$options.filters.humanize_price_for_chart(number)
        }
    }
}
</script>

<style>
</style>
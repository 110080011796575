var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"donors"},[_c('h1',[_vm._v(_vm._s(_vm.$t('donor.title')))]),_c('DataTable',{attrs:{"per-page":_vm.selectedPageSize,"total-rows":_vm.totalRows,"page":_vm.currentPage,"data":_vm.donors,"keys":_vm.donorsTableKeys,"is-busy":_vm.isDonorsLoading},on:{"page-change":function (page) { return (_vm.currentPage = page); },"row-clicked":function (n) { return _vm.$router.push({ name: 'donor-detail', params: { slug: n.slug } }); },"reset-filter":_vm.resetFilter},scopedSlots:_vm._u([{key:"action-bar-left",fn:function(){return [_c('FilterMultiselect',{staticClass:"donor",attrs:{"loader":_vm.getDonorOptions,"label":_vm.$t('donor.label'),"placeholder":_vm.$t('filter.allAlt2')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('DonorName',{staticClass:"text-truncate",attrs:{"data":option,"simple":""}})]}},{key:"tag",fn:function(ref){
var option = ref.option;
return [_c('DonorName',{attrs:{"data":option,"simple":"","element":"span"}})]}},{key:"singleValue",fn:function(ref){
var value = ref.value;
return [_c('DonorName',{staticClass:"value",attrs:{"data":value,"simple":"","element":"span"}})]}},{key:"multipleValue",fn:function(ref){
var values = ref.values;
return [_vm._v(" "+_vm._s(_vm.$tc('donor.pagination.selected', values.length))+" ")]}}]),model:{value:(_vm.selectedDonors),callback:function ($$v) {_vm.selectedDonors=$$v},expression:"selectedDonors"}}),_c('FilterDropdown',{staticClass:"year",attrs:{"bordered":true,"label":_vm.$t('donor.year'),"options":_vm.yearOptions},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('FilterDropdown',{staticClass:"type",attrs:{"bordered":true,"label":_vm.$t('donation.type.label'),"options":_vm.typeOptions},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('FilterDropdown',{staticClass:"type",attrs:{"bordered":true,"label":_vm.$t('donor.risks.filterLabel'),"options":_vm.riskTypeOptions},model:{value:(_vm.selectedRiskType),callback:function ($$v) {_vm.selectedRiskType=$$v},expression:"selectedRiskType"}})]},proxy:true},{key:"action-bar-right",fn:function(){return [_c('FilterDropdown',{staticClass:"sorting",attrs:{"label":_vm.$t('sorting.label'),"options":_vm.sortingOptions,"right":""},scopedSlots:_vm._u([{key:"selected",fn:function(data){return [_vm._v(" "+_vm._s(data.label)+" "),_c('icon',{attrs:{"icon":"arrow_down"}})]}}]),model:{value:(_vm.selectedSortOption),callback:function ($$v) {_vm.selectedSortOption=$$v},expression:"selectedSortOption"}})]},proxy:true},{key:"cell(name)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col col-5 col-md-4 header_row"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col col-icon d-none d-sm-block"},[_c('div',{staticClass:"logo"},[_c('icon',{attrs:{"icon":n.person == 'PHYSICAL' ? 'person' : 'company'}})],1)]),_c('div',{staticClass:"col"},[_c('DonorName',{attrs:{"data":n}}),_c('div',{staticClass:"d-md-none"},[(_vm.isDonorRisky(n))?[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.risks.label'))+":")]),_c('span',{staticClass:"value risks"},[_vm._v(" "+_vm._s(_vm._f("list")(_vm.getDonorRisks(n))))])]:_vm._e()],2)],1)])])]}},{key:"cell(parties)",fn:function(ref){
var n = ref.item;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.parties'))+":")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatParties(n)))])])]}},{key:"cell(risks)",fn:function(ref){
var n = ref.data;
return [_c('div',{staticClass:"col d-none d-md-block col-md-2"},[(_vm.isDonorRisky(n))?[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.risks.label'))+":")]),_c('div',{staticClass:"value risks"},[_vm._v(_vm._s(_vm._f("list")(_vm.getDonorRisks(n))))])]:_vm._e()],2)]}},{key:"cell(donations)",fn:function(ref){
var n = ref.item;
var d = ref.data;
return [_c('div',{staticClass:"col col-7 col-md-4 total"},[_c('icon',{attrs:{"icon":"gift_out"}}),_vm._v(_vm._s(_vm._f("price")(n,0))+" "),_c('div',{staticClass:"d-block d-md-none sub_block"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('donor.parties'))+":")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatParties(d.parties)))])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="detail-tab">
        <h2>{{ $t('party.budget.charts') }}</h2>

        <div class="board">
            <b-row>
                <b-col>
                    <ChartBubble :title="$t('charts.budget.income')" :legend="legend" legend-align="left">
                        <template v-if="chartData">
                            <template v-for="(n, i) in chartData.INCOME">
                                <b-row :key="`chart-income-${i}`" align-v="start" class="chart-row">
                                    <b-col>
                                        <GChart type="BarChart" :data="getChartData(n)" :options="getChartOptions(incomeMax)" />
                                    </b-col>

                                    <b-col class="text-truncate budget-category">
                                        {{ n[0] }}
                                    </b-col>
                                </b-row>
                            </template>
                        </template>
                    </ChartBubble>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <ChartBubble :title="$t('charts.budget.outcome')" :legend="legend" legend-align="left">
                        <template v-if="chartData">
                            <template v-for="(n, i) in chartData.OUTCOME">
                                <b-row :key="`chart-outcome-${i}`" align-v="start" class="chart-row">
                                    <b-col>
                                        <GChart type="BarChart" :data="getChartData(n)" :options="getChartOptions(outcomeMax)" />
                                    </b-col>

                                    <b-col class="text-truncate budget-category">
                                        {{ n[0] }}
                                    </b-col>
                                </b-row>
                            </template>
                        </template>
                    </ChartBubble>
                </b-col>
            </b-row>
        </div>

        <h2>{{ $t('party.budget.cashflow') }}</h2>

        <FilterDropdown class="year" :bordered="true" :label="$t('party.year')" :options="yearOptions" v-model="selectedYear">
        </FilterDropdown>

        <loader v-if="busy" />
        <template v-else>
            <div class="budget-box income">
                <div class="caption">
                    <div>{{ $t('party.budget.totalIncome') }}</div>
                    <div class="value">+ {{ totalIncome | price(0) }}</div>
                </div>
                <table>
                    <tr v-for="(n, i) in budgets.INCOME" :key="`income-${i}`">
                        <td class="numeric gray d-none d-md-table-cell">{{ i + 1 }}</td>
                        <td class="desc">{{ n.description }}</td>
                        <td class="numeric gray">{{ getRation(n.amount, totalIncome) | percentage }}</td>
                        <td class="numeric">{{ n.amount | price(0) }}</td>
                    </tr>
                </table>
            </div>

            <div class="budget-box outcome">
                <div class="caption">
                    <div>{{ $t('party.budget.totalOutcome') }}</div>
                    <div class="value">- {{ totalOutcome | price(0) }}</div>
                </div>
                <table>
                    <tr v-for="(n, i) in budgets.OUTCOME" :key="`outcome-${i}`">
                        <td class="numeric gray d-none d-md-table-cell">{{ i + 1 }}</td>
                        <td class="desc" colspan="2">{{ n.description }}</td>
                        <td class="numeric gray">{{ getRation(n.amount, totalOutcome) | percentage }}</td>
                        <td class="numeric">{{ n.amount | price(0) }}</td>
                    </tr>

                    <tr class="total-row election">
                        <td class="d-none d-md-table-cell"></td>
                        <td class="desc d-table-cell d-md-none" colspan="2">{{ $t('party.budget.election') }}</td>
                        <td class="desc d-none d-md-table-cell" colspan="2">{{ $t('party.budget.election') }}</td>
                        <td class="numeric gray">{{ getRation(totalElection, totalOutcome) | percentage }}</td>
                        <td class="numeric">{{ totalElection | price(0) }}</td>
                    </tr>
                    <tr class="election-row" v-for="(n, i) in budgets.OUTCOME_ELECT" :key="`election-${i}`">
                        <td class="d-none d-md-table-cell"></td>
                        <td class="numeric gray d-none d-md-table-cell">
                            {{ budgets.OUTCOME.length + 1 }}{{ String.fromCharCode(i + 97) }}
                        </td>
                        <td colspan="2" class="desc desc_sub d-table-cell d-md-none">{{ n.description }}</td>
                        <td class="desc d-none d-md-table-cell">{{ n.description }}</td>
                        <td class="numeric gray">{{ getRation(n.amount, totalOutcome) | percentage }}</td>
                        <td class="numeric">{{ n.amount | price(0) }}</td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import FilterDropdown from '@/components/FilterDropdown.vue'
import ChartBubble from '@/components/ChartBubble.vue'
import chartMixin from '@/plugins/chartMixin.js'

import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        FilterDropdown,
        ChartBubble
    },
    mixins: [chartMixin],
    data: function () {
        return {
            donors: null,
            busy: false,
            selectedYear: null,
            budgetsData: null,
            chartData: null
        }
    },
    computed: {
        yearOptions: function () {
            return this.$store.state.budgetYears
        },
        params: function () {
            return {
                year: this.selectedYear,
                party: this.data.id,
                limit: 0
            }
        },
        budgets: function () {
            if (!this.budgetsData) {
                return {}
            }

            return this.budgetsData.reduce((result, n) => {
                var key = n.item_type
                if (n.category == 'výdaje na volby') {
                    key += '_ELECT'
                }
                if (!result[key]) {
                    result[key] = []
                }
                result[key].push(n)
                return result
            }, {})
        },
        totalIncome: function () {
            if (!this.budgets || !this.budgets.INCOME) {
                return 0
            }

            return this.budgets.INCOME.reduce((sum, n) => (sum += n.amount), 0)
        },
        totalOutcome: function () {
            if (!this.budgets || !this.budgets.OUTCOME) {
                return 0
            }

            return this.budgets.OUTCOME.reduce((sum, n) => (sum += n.amount), 0) + this.totalElection
        },
        totalElection: function () {
            if (!this.budgets || !this.budgets.OUTCOME_ELECT) {
                return 0
            }

            return this.budgets.OUTCOME_ELECT.reduce((sum, n) => (sum += n.amount), 0)
        },
        colors: function () {
            return ['#e4002c', '#e3395b', '#ed8298', '#f0c0cc', '#f3dfe4']
        },
        incomeMax: function () {
            if (!this.chartData) {
                return 0
            }

            return this.chartData.INCOME.reduce((max, n) => Math.max(max, ...n.slice(1)), 0)
        },
        outcomeMax: function () {
            if (!this.chartData) {
                return 0
            }

            return this.chartData.OUTCOME.reduce((max, n) => Math.max(max, ...n.slice(1)), 0)
        },
        legend: function () {
            if (!this.chartData) {
                return []
            }

            var legend = []
            var color_index = 0
            for (var y = this.chartData.max_year; y >= this.chartData.min_year; y--) {
                legend.push({ color: this.colors[color_index++], label: y })
            }

            return legend
        }
    },
    created: function () {
        this.loadChartData()
        this.$store.dispatch('loadBudgetYears', this.data.id).then(() => {
            this.selectedYear = this.yearOptions[0]
        })
    },
    watch: {
        params: function () {
            this.loadBudget(this.params)
        }
    },
    methods: {
        loadBudget: function (params) {
            return new Promise((resolve, reject) => {
                this.busy = true

                this.$store.getters.api
                    .get(`${CONFIG.api.endpoints.budget}`, { params: params })
                    .then((response) => {
                        this.budgetsData = response.data.objects
                        resolve()
                    })
                    .catch((e) => {
                        this.budgetsData = null
                        console.log(e)
                        reject(e)
                    })
                    .finally(() => (this.busy = false))
            })
        },
        getRation: function (value, total) {
            return !total ? 0 : value / total
        },
        loadChartData: function () {
            this.$store.getters.api
                .get('chart/budgets', { params: { party: this.data.id } })
                .then((response) => {
                    this.chartData = response.data
                })
                .catch((e) => {
                    console.log(e)
                    this.chartData = null
                })
        },
        getChartData: function (data) {
            if (!data) {
                return []
            }

            var header = ['type'].concat(Array.from(data.slice(1).keys(), (n) => `year-${n}`))

            return [header, data]
        },
        getChartOptions: function (maxValue) {
            var hAxis = {
                ...this.defaultChartOptions.hAxis,
                ...{
                    direction: -1,
                    ticks: [0, maxValue]
                }
            }

            var vAxis = {
                ...this.defaultChartOptions.vAxis,
                ...{
                    ticks: this.getYearAxisTicks([this.chartData.min_year, this.chartData.max_year])
                }
            }

            return {
                ...this.defaultChartOptions,
                ...{
                    bar: { groupWidth: '100%' },
                    colors: this.colors,
                    chartArea: {
                        height: '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    },
                    height: 50,
                    hAxis: hAxis,
                    vAxis: vAxis
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';
@import 'src/scss/utils';
@import '~bootstrap/scss/mixins';

.chart-row:not(:last-of-type) {
    margin-bottom: 20px;
}

.budget-category::first-letter {
    text-transform: uppercase;
}

.budget-box {
    @include make-rounded();

    background: $background-light;
    padding: 48px;
    padding-right: 0;

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }

    &.income {
        margin-top: 24px;
        margin-bottom: 16px;

        @include media-breakpoint-down(sm) {
            margin-top: 10px;
        }

        .caption .value {
            color: $color-green;
        }
    }

    &.outcome {
        .caption .value {
            color: $color-red;
        }
    }

    .caption {
        font-size: 16px;
        font-weight: 700;
        border-bottom: 3px double $light;
        padding: 15px 30px 15px 0;
        display: flex;
        justify-content: space-between;

        *:last-child {
            text-align: right;
            font-weight: 500;
            font-family: $font-family-monospace;
        }
    }

    table {
        width: 100%;
        font-size: 10px;
        color: $body-color;
        border-collapse: separate;

        tr {
            td {
                padding: 15px 7.5px;
                border-bottom: 1px dashed $light;

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
                &.numeric {
                    text-align: right;
                }
                &:first-child {
                    padding-left: 0;
                    padding-right: 30px;
                    border-bottom-color: transparent;
                    text-align: left;
                }
                &:nth-child(2) {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 30px;
                    padding-left: 30px;

                    @include media-breakpoint-down(sm) {
                        padding-right: 0px;
                        padding-left: 0px;
                    }
                }
                &.desc {
                    width: 100%;
                    max-width: 0;
                }
                &.desc_sub {
                    width: 100%;
                    max-width: 0;
                    padding-left: 15px !important;
                }
                &.gray {
                    color: $light;
                }
            }

            &.election-row {
                td:first-child {
                    padding: 0;
                }
                td:nth-child(2) {
                    padding-left: 0;
                    padding-right: 30px;
                    border-bottom-color: transparent;
                }
                td:nth-child(3) {
                    padding-left: 0;
                }

                .desc {
                    font-weight: 300;
                }
            }
        }
    }
}
</style>
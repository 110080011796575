<template>
    <div class="container-fluid">
        <div class="grid-row no-gutters" :class="{'text-center': centered}">
            <div class="grid-col">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        centered: {type: Boolean, default: false}
    }
}
</script>

<style>

</style>
var config = {
    api: {
        url: "http://localhost:8000/api/v1/",
        endpoints: {
            party: "party/",
            donor: "donor/",
            donation_by_donor: "donation_by_donor/",
            budget: "budget_item/",
            donation: "donation/",
            tender: "tender/",
            eusub: "eu_subsidy/"
        }
    },
    blogDatlab: {
        url: "https://datlab.eu/blog/ghost/api/v3/content/",
        key: "b89dbfd64842478a86d35ee33a"
    }
}

if (process.env.VUE_APP_SERVER == "dev01_kuba") {
    config.api.url = "http://localhost:22081/api/v1/"
}

if (process.env.VUE_APP_SERVER == "production") {
    config.api.url = "https://politickefinance.cz/api/v1/"
    config.gtmkey = "GTM-KSMW4XL"
}

if (process.env.VUE_APP_SERVER == "staging") {
    config.api.url = "https://polfin.datlab.eu/api/v1/"
    config.gtmkey = "GTM-KSMW4XL"
}

export const CONFIG = config

<template>
    <div class="detail-tab">
        <div class="board">
            <b-row>
                <b-col class="mb-3 col col-6 col-lg-3 order-1">
                    <ValueBubble icon="fhealth" :title="$t('party.overview.index')" :data-value="data.hindex" class="hindex">
                        {{ data.hindex }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-12 col-lg-5 order-3 order-lg-2">
                    <ValueBubble icon="warn" :title="$t('party.overview.risks')">
                        {{ $t('party.overview.risksRatio', { risky: data.donors_risky, total: data.donors_total }) }}
                    </ValueBubble>
                </b-col>

                <b-col class="mb-3 col col-6 col-lg-4 order-2 order-lg-3">
                    <ValueBubble icon="pie_chart" :title="$t('party.overview.donations')" class="donations">
                        <b-row align-h="center" align-v="center" no-gutters>
                            <b-col cols="auto">
                                <span>{{ donationsRatio | percentage }}</span>
                            </b-col>
                            <b-col class="col d-none d-md-block">
                                <b-progress :value="donationsRatio" :max="1" height="12px"></b-progress>
                            </b-col>
                        </b-row>
                    </ValueBubble>
                </b-col>
            </b-row>
        </div>

        <h2>{{ $t('party.overview.topDonors') }}</h2>

        <DataTable
            class="top-donors"
            :data="donors"
            :keys="tableKeys"
            simple
            @row-clicked="(n) => $router.push({ name: 'donor-detail', params: { slug: n.slug } })"
        >
            <template #cell(name)="{ data: n }">
                <div class="col col-5 col-sm-6 col-md-6 header_row">
                    <div class="row align-items-center">
                        <div class="col col-icon d-none d-sm-block">
                            <div class="logo">
                                <icon :icon="n.person == 'PHYSICAL' ? 'person' : 'company'" />
                            </div>
                        </div>
                        <div class="col">
                            <DonorName :data="n" />

                            <div class="d-md-none">
                                <template v-if="isDonorRisky(n)">
                                    <div class="label">{{ $t('donor.risks.label') }}:</div>
                                    <div class="value risks">{{ getDonorRisks(n) | list }}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(risks)="{ data: n }">
                <div class="col d-none d-md-block col-md-3 total">
                    <template v-if="isDonorRisky(n)">
                        <div class="label">{{ $t('donor.risks.label') }}:</div>
                        <div class="value risks">{{ getDonorRisks(n) | list }}</div>
                    </template>
                </div>
            </template>
            <template #cell(donations)="{ item: n }">
                <div class="col col-7 col-sm-6 col-md-3 total"><icon icon="gift_out" />{{ n | price(0) }}</div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import ValueBubble from '@/components/ValueBubble.vue'
import DataTable from '@/components/DataTable.vue'
import DonorName from '@/components/DonorName.vue'
import donorMixin from '@/plugins/donorMixin.js'

import { CONFIG } from '@/config.js'

export default {
    props: {
        data: { type: Object, required: true }
    },
    components: {
        ValueBubble,
        DataTable,
        DonorName
    },
    mixins: [donorMixin],
    data: function () {
        return {
            donors: null
        }
    },
    computed: {
        donationsRatio: function () {
            return this.data.income ? this.data.donations / this.data.income : 0
        },
        tableKeys: function () {
            return [{ id: 'name', top: true }, { id: 'risks' }, { id: 'donations' }]
        }
    },
    created: function () {
        this.loadTopDonors()
    },
    methods: {
        loadTopDonors: function () {
            var params = {
                min_year: new Date().getFullYear() - 5,
                party: this.data.id,
                limit: 5,
                order_by: '-donations'
            }

            return this.$store.getters.api
                .get(`${CONFIG.api.endpoints.donor}`, { params: params })
                .then((response) => {
                    this.donors = response.data.objects
                })
                .catch((e) => {
                    this.donors = null
                    console.log(e)
                })
        }
    }
}
</script>

<style lang="scss">
</style>